import { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from 'react';

import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

import dateparser from 'datemath-parser';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';

import { ReferenceArea, Brush, ReferenceDot } from 'recharts';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useKeycloak, asUrl } from 'react-kcfetch';
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from "react-use-audio-player"

// import useSWR from 'swr';

// import DBChart from 'DBChart';
import NoiseReportForm from './NoiseReport';

import { shortTimeString , titleCase, useDeploymentId, useKeycloakJSONSWR, dateMath } from '../utils';
import { apiUrl, buttonIcons } from '../config'
import { useMediaQuery } from '@mui/material';
import DBChart from '../components/DBChart';
import MediaPlayer from '../components/MediaPlayer';
import { useTheme } from '@mui/styles';

const MINS = 60*1000
const DataView = ({ startTime, endTime, timeDiff }) => {
  const theme = useTheme();
  const deployId = useDeploymentId();
  console.log(startTime, endTime, timeDiff)


  const interval = timeDiff > 3*60*MINS ? '10m' : 90*MINS ? '20s' : null
  console.log(interval)




  // query and prepare the data
  let { data: spl, error: splError } = useKeycloakJSONSWR(
    startTime && endTime && asUrl(`${apiUrl}/spl/${deployId}`, { start: startTime, end: endTime, interval }), 
    { refreshInterval: 0, revalidateOnFocus: false });
  if(splError) console.error(splError);

  // let data = [];
  
  spl = useMemo(() => {
    if(!spl || !spl.length) return spl;
    if(spl[0].count) {
      return spl.map(d => ({ ...d, laeq: d.avg_laeq, time: Date.parse(d.time) }))
    }

    return spl.filter(d => d.laeq)
        .map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
        .sort((a, b) => a.time - b.time)
  }, [spl])

  const minSpl = useMemo(() => spl && Math.min(...spl.map(d=> d.min_laeq || d.laeq)), [spl])

  let { data: audioList, error: audioListError } = useKeycloakJSONSWR(
    startTime && endTime && asUrl(`${apiUrl}/file/audio/${deployId}`, { start: startTime, end: endTime, interesting: 1 }), 
    { refreshInterval: 0, revalidateOnFocus: false });
  if(audioListError) console.error(audioListError);
  console.log('audio', audioList)

  audioList = useMemo(() => {
    return audioList && audioList.map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
  }, [audioList])

  // const [ selectedAudio, setSelectedAudio ] = useState();
  // console.log(audioList && audioList.map(f => f.time + 0))

  return (<>
  {/* <Stack>
    {selectedAudio && <AudioPlayerProvider key={selectedAudio.id}>
        <MediaPlayer
          sx={{ alignSelf: 'stretch', margin: '1em' }}
          title={<>
            {new Date(selectedAudio.time).toLocaleTimeString()} {'  '}
            <small>{new Date(selectedAudio.time).toLocaleDateString()
        }</small></>}
        artist={`Average: ${selectedAudio.avg_laeq ? Math.round(selectedAudio.avg_laeq) : '-'} dB(A), Peak: ${selectedAudio.max_laeq ? Math.round(selectedAudio.max_laeq) : '-'} dB(A)`}
        noVolume src={`${apiUrl}/file/id/${selectedAudio.id}`} />
      </AudioPlayerProvider>}
    </Stack> */}
  <Box px='1em'>
    <Typography variant='h5'>
      {`${shortTimeString(startTime)} - ${shortTimeString(endTime)} ${!isNaN(new Date(endTime)) ? `on ${new Date(endTime).toLocaleDateString()}` : ''}`}
    </Typography>
    </Box>
  {/* </>)}> */}
    {/* <Box height='60vh' display='flex' flexDirection='column'> */}
    {/* <pre>{JSON.stringify(selected, null, 2)}</pre> */}
    <DBChart data={spl} height='10rem' brush audioList={audioList}>
    {/* {audioList && audioList.map(f => 
      // <Tooltip key={f.id} title=''>
        // <ReferenceArea 
        //   key={f.id} x1={f.time+0} x2={f.time + 10 * 1000} 
        //   fill={!(selectedAudio && selectedAudio.id === f.id) ? theme.palette.background.default : theme.palette.primary.light}
        //   stroke={theme.palette.text.primary} // selectedAudio && selectedAudio.id === f.id ? theme.palette.text.primary : null
        //   onClick={() => setSelectedAudio(selectedAudio && selectedAudio.id === f.id ? null : f)} />
        <ReferenceDot 
          key={f.id} x={f.time + 5 * 1000} y={minSpl - 2} r={9}
          fill={!(selectedAudio && selectedAudio.id === f.id) ? theme.palette.background.default : theme.palette.primary.light}
          stroke={theme.palette.text.primary} // selectedAudio && selectedAudio.id === f.id ? theme.palette.text.primary : null
          onClick={() => setSelectedAudio(selectedAudio && selectedAudio.id === f.id ? null : f)} />
      // </Tooltip>
    )} */}
    </DBChart>
  </>)
   // </SwipeDrawer2>
}


const QueryView = () => {
    const deployId = useDeploymentId();
    const [ startTime, setStartTime ] = useState(null);
    const [ endTime, setEndTime ] = useState(null);
    const startTimeStr = useMemo(() => startTime ? startTime.toISOString() : null, [startTime]);
    const endTimeStr = useMemo(() => endTime ? endTime.toISOString() : null, [endTime]);

    const [ startTime_, setStartTime_ ] = useState(null);
    const [ endTime_, setEndTime_ ] = useState(null);
    const [ timeDiff, setTimeDiff ] = useState(null);

    return (<>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction='row'>
            <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                value={startTime}
                onChange={(newValue) => { setStartTime(newValue) }}
            />
            <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="DateTimePicker"
                value={endTime}
                onChange={(newValue) => { setEndTime(newValue) }}
            />
            <Button onClick={() => { 
                setStartTime_(startTimeStr);
                setEndTime_(endTimeStr);
             }}>Enter</Button>
            </Stack>
        </LocalizationProvider> */}
        <Typography variant='h4' align='center' py={2}>
          {deployId}
        </Typography>
        <DateRange setTimes={([s, e, d]) => { setStartTime_(s); setEndTime_(e); setTimeDiff(d) }} />
        {startTime_ && endTime_ && <DataView startTime={startTime_} endTime={endTime_} timeDiff={timeDiff} />}
    </>)
}

const DateRange = ({ setTimes }) => {
    const [ start, setStartTime ] = useState(() => new Date(Date.now() - 45 * 60 * 1000));
    const [ end, setEndTime ] = useState(() => new Date(Date.now()));
    const startTimeStr = useMemo(() => start ? start.toISOString() : null, [start]);
    const endTimeStr = useMemo(() => end ? end.toISOString() : null, [end]);

    const [ relStart, setRelStart ] = useState('15m');
    // const [ relStart, setRelStart ] = useState('15m');

    const [rangeType, setRangeType] = useState('relative');
    const timeDiff = useMemo(() => rangeType === 'absolute' ? start && end && end - start : relStart && dateMath(`now+${relStart}`), [])

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction='row' justifyContent='center' alignItems='center' py={2} spacing={1}>
            <ToggleButtonGroup value={rangeType} exclusive sx={{ mb: 2, display: 'block' }}>
            {['relative', 'absolute'].map(x => (
                <ToggleButton key={x} value={x} onClick={() => setRangeType(x)}>{x}</ToggleButton>
            ))}
            </ToggleButtonGroup>

            {rangeType === 'absolute' ? (<>
                <DateTimePicker renderInput={p => <TextField {...p} />} value={start} onChange={x => { setStartTime(x) }} />
                <DateTimePicker renderInput={(p) => <TextField {...p} />} value={end} onChange={x => { setEndTime(x) }} />
                <Typography>{Math.round(timeDiff / 60 / 60)/1000} hours</Typography>
            </>) : rangeType === 'relative' ? (<>
                {/* <TimePicker value={value} onChange={x => setValue(x)} renderInput={(params) => <TextField {...params} />} /> */}
                {/* <FormGroup row>
                    <Button variant="contained" disableElevation disable>now-</Button>
                </FormGroup> */}
                <TextField label='now minus' value={relStart} onChange={e => setRelStart(e.target.value)} />
                
                {/* <DateTimePicker renderInput={p => <TextField {...p} />} value={start} onChange={x => { setStartTime(x) }} /> */}
            </>) : null}
            <Button onClick={() => setTimes(rangeType === 'absolute' ? [ startTimeStr, endTimeStr, timeDiff ] : [`now-${relStart}`, 'now', timeDiff])}>Search</Button>
        </Stack>
    </LocalizationProvider>
}

// const AbsRelDateParser = ({ time, setTime }) => {
//     const [ relStart, setRelStart ] = useState('15m');
//     return (
//         <FormGroup row>
//             <DateTimePicker renderInput={p => <TextField {...p} />} value={time} onChange={x => { setTime(x) }} />
//             <Button variant="contained" disableElevation>
//                 @example.com
//             </Button>
//         </FormGroup>
//     )
// }

// const relativeToAbsolute = () => {

// }

export default QueryView;
