import { useState, useEffect, useRef, useMemo, useCallback } from 'react';

import { Link, useParams } from "react-router-dom";

import { useKeycloak } from 'react-kcfetch';

import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';


import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

import { CenterBox, ButtonSelect } from '../components/utils';

import MapView from './Map';

import { titleCase, shortTimeString, useDeploymentId } from '../utils';
import { buttonIcons, apiUrl } from '../config';
import { WideButton } from '../components/UserInput';



const QontoConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': { borderTopWidth: 3, borderRadius: 2 },
  '&.Mui-active .MuiStepConnector-line': { borderColor: theme.palette.primary.main },
  '&.Mui-completed .MuiStepConnector-line': { borderColor: theme.palette.primary.main },
  
}));

const QontoStepIcon = ({ active, completed }) => {
  const theme = useTheme()
  return (
    <div style={{ color: active ? theme.palette.primary.main : theme.palette.text.secondary }}>
      {completed ? (
        <DoneIcon size='small' color='primary' />
      ) : (
        <div style={{ 
          width: active ? 10 : 8, height: active ? 10 : 8, borderRadius: '50%', 
          backgroundColor: active ? theme.palette.primary.main : theme.palette.text.secondary }} />
      )}
    </div>
  );
}


const NoiseReportForm = ({ metadata={}, defaultOpen=false, buttonProps: {sx: buttonSx, ...buttonProps}={} }) => {
    const depId = useDeploymentId();
    const { kcfetch } = useKeycloak();
    const [ open, setOpen ] = useState(defaultOpen);
    const [ showConfirm, setShowConfirm ] = useState(false);
  
    const [ activeStep, setActiveStep ] = useState(0);
    const [ formData, setFormData ] = useState({});
    const [ submitting, setSubmitting ] = useState(false);
    const [ submitError, setSubmitError ] = useState();
    const [ reportId, setReportId ] = useState();

    // const fixedMeta = metadata //useRef(metadata);
    const fixedMeta = useMemo(() => metadata, [open])

    // console.log('form data:', formData, fixedMeta)
  
    const updateStep = (d, n=1) => { 
      setFormData({ ...formData, ...d });
      n && setActiveStep(activeStep + n);
    }
  
    const submitReport = () => {
      setSubmitting(true)

      const data = { ...formData, ...fixedMeta }
      // return new Promise((re, rj) => {
      //   setTimeout(() => re({ report_id: "mMjEQX8BJUe9ybPPTaa_" }), 300)
      // })
      console.log(`${apiUrl}/reports/${depId}`, data)
      kcfetch(`${apiUrl}/reports/${depId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      }).then(d => d.json()).then(dd => {
        const [report_id] = dd;
        console.log('submitted report', data, report_id)
        setActiveStep(3); // XXX
        setSubmitError(null)
        setReportId(report_id)
        setSubmitting(false)
        setFormData({})
      }).catch(error => { 
        console.error(error)
        console.log('your report:', formData)
        console.log(error)
        setSubmitError({ 
          error,
          report: formData,
          submitTime: Date.now()
        });
        setSubmitting(false);
      })
      
    }
  
    const clearReport = () => {
      setFormData({});
      setActiveStep(0);
      setSubmitError(null);
    }
  
    return (<CenterBox>
      <WideButton onClick={e => { clearReport(); setOpen(true) }} sx={buttonSx} {...buttonProps}>Log Problem Noise</WideButton>
      {/* <Button onClick={e => {
        clearReport(); setOpen(true)
      }} sx={{
        margin: '1em auto 1.5em',
        width: '90%',
        borderRadius: '20px',
        ...buttonSx,
      }} variant='contained' {...buttonProps}>Log Problem Noise</Button> */}
      {!!open ? 
        <Drawer anchor='bottom' open={!!open} onClose={() => Object.keys(formData).length ? setShowConfirm(true) : setOpen(false)}
        PaperProps={{ sx: { borderRadius: '40px 40px 0 0' } }}>
          <Stack direction="row" justifyContent='center' spacing={4}>
          <Button onClick={() => { 
              Object.keys(formData).length ? setShowConfirm(true) : setOpen(false)
            }} 
            sx={{ height: '1.5em', width: '100%' }} 
            size="small" startIcon={<CloseIcon />}>Close</Button>
          {/* <Button onClick={() => { clearReport() }} size="small">Reset</Button> */}
          </Stack>
          <Stepper activeStep={activeStep} sx={{ padding: '0.2em 0.6em' }} connector={<QontoConnector />}>
            <Step><StepLabel StepIconComponent={QontoStepIcon}>What</StepLabel></Step>
            <Step><StepLabel StepIconComponent={QontoStepIcon}>Where</StepLabel></Step>
            <Step><StepLabel StepIconComponent={QontoStepIcon}>Summary</StepLabel></Step>
          </Stepper>
          {submitting ? <Box display='flex' justifyContent='center' sx={{ padding: '4em 0 6em' }}>
            <CircularProgress color="primary" size={80} />
          </Box>
          : activeStep < 1 ? <NoiseReport updateStep={updateStep} form={formData} /> 
          : activeStep < 2 ? <NoiseMap updateStep={updateStep} form={formData} /> 
          : activeStep < 3 ? <NoiseSummary updateStep={updateStep} form={formData} submitReport={submitReport} />
          : submitError ? <ReportError error={submitError} close={() => setOpen(false)} submit={submitReport} /> :
          <ReportDone reportId={reportId} setOpen={setOpen} />}
        {/* <code style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>{JSON.stringify(formData)}</code> */}
        <Dialog open={showConfirm} onClose={() => setShowConfirm(false)} onKeyUp={e => { if(e.key === 'Enter') { clearReport();setOpen(false);setShowConfirm(false) } }}>
          <DialogContent>
            <div>Are you sure you want to cancel this report? You will lose any progress.</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setShowConfirm(false) }}>Oops, no I don't</Button>
            <Button onClick={() => {
              clearReport()
              setOpen(false)
              setShowConfirm(false)
            }} variant='outlined'>Yes</Button>
          </DialogActions>
        </Dialog>
        </Drawer>
      : null}
    </CenterBox>);
}

  
  
  
const NoiseReport = ({ updateStep, form: { 
      noisetype: _nt='Construction',
      activity: _ca='Sleeping',
      currentlyHappening: _ch=true,
      time: _rt, startTime: _st, endTime: _et,
    }={} }) => {
    const [ noisetype, setNoiseType ] = useState(_nt);
    const [ activity, setActivity ] = useState(_ca);
    const [ currently, setCurrently ] = useState(_ch);
  
    let [reportTime] = useState(() => new Date() - 0, []);
    reportTime = _rt || reportTime;
    let [ timeRange, setTimeRange ] = useState(
      [_st || reportTime - 1000 * 60 * 20, _et || reportTime + 2]);
    if(currently) {
      timeRange = [timeRange[0], reportTime]
    }
    const diff = Math.round((timeRange[1] - timeRange[0]) / (1000*60))
    
    return <Box sx={{ padding: '2em', paddingTop: '0.1em' }}>
      <ButtonSelect title='Noise Type' options={[
        'Construction',
        'Bar',
        'Street',
        'Delivery',
        'HVAC',
        // 'Music',
        // 'Garbage',
        'Other',
      ].map(l => ({ value: l, icon: buttonIcons[l] }))} 
      selected={noisetype} setSelected={value => setNoiseType(value)} />
      <ButtonSelect title='Current Activity' options={[
        'Sleeping',
        'Working',
        'Leisure',
        'Cooking',
        // 'Walking',
        // 'Parenting',
        'Other',
      ].map(l => ({ value: l, icon: buttonIcons[l] }))} 
      selected={activity} setSelected={value => setActivity(value)} />
      <Box sx={{ margin: '1em 0.5em' }} display='flex' alignItems='center'>
          <Typography variant='h6' sx={{ marginRight: '1em' }}>Is the sound happening now?</Typography>
          {/* <FormControlLabel control={
            // <Switch checked={currently} onChange={e => setCurrently(e.target.checked)} />
            
          } 
            label={<Typography variant='subtitle1'>{currently ? 'Yes!' : 'Not right now'}</Typography>}
            labelPlacement='end' /> */}
            <SonycSwitch checked={currently} onChange={e => setCurrently(e.target.checked)} />
      </Box>
  
      <Box sx={{ margin: '1em 0.5em' }}>
          <Typography variant='h6' sx={{ marginBottom: 1.5 }}>
            {currently ? `The noise has been happening:` : `The noise happened:`}
          </Typography>
      <TimeSlider className={currently ? 'MuiSlider-currently' : null}
          defaultValue={30} valueLabelDisplay="on" marks size='small'
          getAriaValueText={x => shortTimeString(x)}
          valueLabelFormat={x => (
            Math.abs(new Date(x) - reportTime) < 10000 ? 'Now' : 
            shortTimeString(x, { hour: 'numeric', hour12: true }).toLowerCase()
          )}
          value={timeRange} 
          onChange={(e, value) => { setTimeRange(currently ? [value[0], reportTime] : value) }}
          min={reportTime - 1000 * 60 * 60} max={reportTime} step={1000 * 60 * 5} />
      </Box>
      
      <Stack spacing={2} direction="row">
        <Button onClick={() => updateStep({
          noisetype, 
          activity, 
          startTime: new Date(timeRange[0]).toISOString(),
          endTime: new Date(timeRange[1]).toISOString(), 
          // duration: timeRange,
          currently, 
          time: new Date(reportTime).toISOString(),
          durationMinutes: timeRange && (timeRange[1] - timeRange[0]) / (1000*60)
        })} sx={{
          margin: '1em auto 1.5em',
          width: '90%',
          borderRadius: '20px',
        }} variant='contained'>Locate the noise</Button>
      </Stack>
    </Box>
  }
  

const NoiseMap = ({ updateStep, form }) => {
  const [ record, setRecord ] = useState(
    // xzzzxx{
    // address: {
    //   house_num: 432, street: 'Rogers Ave', city: 'Brooklyn', state: 'NY', zip: '11225',
    // },
    // location: [-73.9538383, 40.6620753],
  // }
  );

  const setLocation = useCallback((update) => {
    console.log('updating location', update)
    setRecord({ ...record, ...update })
  }, [record]);

  return <Box display='flex' flexDirection='column' sx={{ height: '85vh', overflowX: 'hidden' }}>
    {/* <Box display='flex' flexDirection='column' sx={{ padding: '2em' }}>
      <Typography variant='h5'>Press on the map to drop a pin at the location the noise is coming from.</Typography>
    </Box> */}
      <MapView setLocation={setLocation} currentRecord={record} confirmLocation={() => {
        updateStep({ record, coordinates: record?.coordinates, address: record?.address })
      }} form={form} />
    <Box sx={{ padding: '0.5em 1em' }}>
      <Stack spacing={2} direction="row">
        <Button size='small' onClick={() => updateStep({ record, coordinates: record?.coordinates, address: record?.address }, -1)}>Previous</Button>
        <Button size='small' onClick={() => updateStep({ record, coordinates: record?.coordinates, address: record?.address })} variant='contained'>Next</Button>
      </Stack>
    </Box>
  </Box>
}


const NoiseSummary = ({ updateStep, form, submitReport }) => {
  const [ comment, setComment ] = useState('');

  return <Box sx={{ padding: '2em' }}>
    <Typography variant='h5' gutterBottom>Problem Noise Details.</Typography>
    {/* <Box display='flex'></Box> */}
    <Stack direction="row" sx={{ flexWrap: 'wrap', '>*': {margin: '0.5em'} }}>
      <ReportCard data={form} sx={{ flexGrow: 1, flexShrink: 1 }} variant='outlined' />
      <Card  sx={{ flexGrow: 5, flexShrink: 3 }} variant='outlined'>
        <CardContent>
          <TextField
            label="Additional Information (optional)"
            multiline
            rows={4}
            value={comment} onChange={e => setComment(e.target.value)}
            placeholder="Is there any other information you'd like to provide to describe the noise source?"
            sx={{ width: '100%', flexGrow: 1 }}
          />
        </CardContent>
      </Card>
      
    </Stack>
    
    <Stack spacing={2} direction="row" sx={{ padding: '1em 0 0 0' }}>
      <Button onClick={() => updateStep({ comment }, -1)}>Previous</Button>
      <Button onClick={() => { updateStep({ comment }); submitReport() }} variant='contained'>Submit</Button>
    </Stack>
  </Box>
}


export const ReportCard = ({
  data: {
      noisetype, startTime, endTime, duration,
      coordinates: { latitude, longitude }={},
      record: { address={} }={}, 
      backgroundLevel, peakLevel, flexShrink=0,
    }, sx, ...props
  }) => {
    // legacy
    if(!startTime && duration) startTime = duration[0];
    if(!endTime && duration) endTime = duration[1];

  return (
    <Card sx={{ flexShrink, ...sx }} variant='outlined' {...props}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
        {buttonIcons[noisetype]} You heard "{noisetype}" noise at:
        </Typography>
        {address && (address.house_num || address.street) ? <>
            <Typography variant='h5'>
              <b>{address.house_num} {titleCase(address.street)}</b>
            </Typography>
            {address.city && <Typography gutterBottom>
              {titleCase(address.city)}, {address.state} {address.zip}
            </Typography>}
        </> : latitude && longitude ? <Typography gutterBottom>
            {titleCase(address.city)}, {address.state} {address.zip}
          </Typography> : null}
        <Box sx={{ color: 'text.secondary' }}>
          <Typography>
          The noise took place between:
          </Typography>
          <Typography>
          <b>{shortTimeString(startTime) || '--'}</b> and <b>{shortTimeString(endTime) || '--'}</b>
          </Typography>
          <Typography>
          On {new Date(endTime).toLocaleDateString()}
          </Typography>
        </Box>

        {backgroundLevel && <Typography>
        Background Noise Level: {backgroundLevel} dB(A)
        </Typography>}
        {peakLevel && <Typography>
        Peak Noise Level: {peakLevel} dB(A)
        </Typography>}
      </CardContent>
    </Card>
  )
}

const ReportDone = ({ reportId, setOpen }) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' sx={{ padding: '2em 2em 4em' }}>
      <Box sx={{ backgroundColor: '#44f059', borderRadius: '100%', padding: '1em', mb: 2 }}>
        <DoneIcon />
      </Box>
      <Typography variant='h3'  mb={1} align='center'>
        {/* All set! Thanks for your help. */}
        Thank you! 
      </Typography>
      <Typography variant='h5' gutterBottom align='center' mb={3}>
        Now, help us <b>identify the noise</b> you're concerned with.
      </Typography>
      {/* <Typography variant='h6' gutterBottom align='center' color='red'>
        This is a preview so your report was not actually saved.
      </Typography> */}
      <Stack spacing={2} direction="column">
      {reportId && <Tooltip title={
        // <div style={{ fontSize: '1.1em' }}>
          'You will be presented with a few audio clips to see if we can find what you heard.'
        // </div>
      }>
        <Button component={Link} to={`/tag/${reportId}`} variant='selected'>Listen to audio clips</Button>
      </Tooltip>}
      <Button onClick={() => setOpen(false)} size='small'>Return to data view.</Button>
    </Stack>
    </Box>
  )
}

export const ReportError = ({ error, buttons, children, close, closeTo, submit, subject='SONYC Home Report Submission Error',  }) => {
  const { keycloak, initialized } = useKeycloak();
  const username = keycloak?.tokenParsed?.preferred_username;

  const closeProps = (closeTo && { to: closeTo, component: Link });
  console.log(error)

  return <Box display='flex' flexDirection='column' alignItems='center' sx={{ padding: '1em 0.5em 2em' }}>
  <Box sx={{ backgroundColor: 'red', borderRadius: '100%', padding: '1em' }}>
    <ErrorIcon />
  </Box>
  <Typography variant='h3' gutterBottom align='center'>
    Oh no! 
  </Typography>
  <Typography variant='h6' gutterBottom align='center'>
    There seemed to be an unexpected error submitting your report. <br/>If this problem persists, please let us know.
  </Typography>
  {/* {error && } */}
  {children}
  <Stack spacing={2} direction="column" textAlign='center'>
  {submit && <Button onClick={() => submit()} size='small'>Retry Submitting</Button>}
  {subject && error && <Button
      variant="contained" rel="noopener noreferrer" target='_blank'
      href={`mailto:bsteers@nyu.edu?subject=${subject}&body=${(
        `${username} had an issue submitting a report.%0D%0D` + 
        `Here is a dump of the report and error:%0D${JSON.stringify(error)}%0D%0D`
      )}`}>
      Submit Error Report
    </Button>}
    {buttons}
    <Button onClick={() => close && close()} {...closeProps} size='small'>Return to data view.</Button>
  </Stack>
</Box>
}


export const SonycSwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 30,
  padding: 0,

  '& .MuiSwitch-thumb': {
    width: 24,
    height: 28,
    borderRadius: 6,
    boxShadow: `inset 0 0 2px ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },

  '& 	.MuiSwitch-switchBase': {
    padding: 0,
    '&.Mui-checked': {
      // transform: 'translateX(14px)',
      '& + $track': { opacity: 1 },
    },
  },

  '& .MuiSwitch-track': {
    width: 56,
    height: 28,
    borderRadius: 6,
    boxShadow: `inset 0 0 4px ${theme.palette.text.primary}`,
    opacity: '1 !important',
    position: 'relative',
    background: 'black',
    
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.background.default,
      textAlign: 'center',
      transition: 'opacity 0.3s',
      fontSize: 14,
      fontWeight: 600,
    },

    '&:before': { content: '"Yes"', left: 4, opacity: 0 },
    '&:after': { content: '"No"', right: 4, color: 'white' },
  },

  '&	.Mui-checked': {
    '&.MuiSwitch-switchBase': { transform: 'translateX(33px)' },
    '& + .MuiSwitch-track': {
      '&:before': { opacity: 1 },
      '&:after': { opacity: 0 }
    },
  },
}));

const TimeSlider = styled(Slider)(({ theme }) => ({
  // width: '75%',
  // margin: 'auto',
  '& 	.MuiSlider-valueLabel': {
    // left: 'calc(-50% - 5px)',
    // top: -22,
    // '& *': {
    //   transform: 'none',
    //   width: '4em',
    //   background: 'transparent',
    //   color: '#000',
    // },
    background: 'transparent',
    color: theme.palette.text.primary,
    width: '4em',
    top: '-24px',
    fontSize: '0.8rem',
    '&.MuiSlider-valueLabelOpen': { transform: 'none' },
  },
  '&.MuiSlider-currently 	.MuiSlider-thumb[data-index="1"]': {
      backgroundColor:  "#AACCFF",
      pointerEvents: 'none',
  }
}))

// const PresentThumb = props => {
//   return <span {...props} style={{ 
//     ...props.style,
//     ...(props["data-index"] === 0 ? 
//           { pointerEvents: 'all'} 
//         : props["data-index"] === 1 ? 
//           { backgroundColor:  "#AACCFF" } 
//         : {})
//    }} />;
// }

// const PrevThumb = props => {
//   return <span {...props} />;
// }

// const timeLabel = (value) => {

//   // take current time, subtract 100 * 5 minutes
//   // let now = new Date()
//   let twoHoursAgo = timeAccessed - (100*60000)
//   // now iterate 100 times, add 5 minutes 
//   // every time and add those to an array,
//   let timeIncrements = []
//   for (let i=0; i<101; i++){
//     timeIncrements[i] = new Date(twoHoursAgo + ([i] * 60000 ))
//   }
//   let t = timeIncrements[value]
//   if(props.timePresent && value === 100 ){
//     return(
//       <span style={{marginLeft: '1em'}}>Now</span>
//     ) 
//   } else {
//     return `${t.getHours()}:${t.getMinutes().toString().padStart(2,"0")}`
//   }
// }

  // root: {
  //   // width: 42,
  //   width: 64,
  //   height: 30,
  //   padding: 0,
  //   // margin: theme.spacing(1),
  // },
  // switchBase: {
  //   padding: 1,
  //   '&$checked': {
  //     transform: 'translateX(16px)',
  //     // color: theme.palette.common.white,
  //     color: 'white',
  //     '& + $track': {
  //       backgroundColor: '#32659F',
  //       opacity: 1,
  //       border: 'none',
  //     },
  //   },
  //   '&$focusVisible $thumb': {
  //     color: '#32659F',
  //     border: '6px solid #fff',
  //   },
  // },
  // thumb: {
    // width: 24,
    // height: 28,
    // borderRadius: 6,
    // boxShadow: 'inset 0 0 2px #000000',
  // },
  // track: {
  //   // borderRadius: 26 / 2,
  //   borderRadius: 6,
  //   // border: `1px solid ${theme.palette.grey[400]}`,
  //   // border: '1px solid #32659f',
  //   // backgroundColor: theme.palette.grey[50],
  //   backgroundColor: 'grey',
  //   opacity: '1 !important',
  //   // transition: theme.transitions.create(['background-color', 'border']),
  //   position: 'relative',
  //   '&:before, &:after': {
  //     display: 'inline-block',
  //     position: 'absolute',
  //     top: '50%',
  //     width: '50%',
  //     transform: 'translateY(-50%)',
  //     color: '#fff',
  //     textAlign: 'center',
  //     transition: 'opacity 0.3s',
  //   },
  //   '&:before': {
  //     content: '"Yes"',
  //     left: 4,
  //     opacity: 0,
  //   },
  //   '&:after': {
  //     content: '"No"',
  //     right: 4,
  //   },
  // },
  // checked: {
  //   '&$switchBase': {
  //     transform: 'translateX(38px)',
  //   },
  //   '& + $track': {
  //     // background: 'linear-gradient(to right, #43cea2, #185a9d)',
  //     '&:before': {
  //       opacity: 1,
  //       // transition: 'opacity 0.3s',
  //     },
  //     '&:after': {
  //       opacity: 0,
  //       // transition: 'opacity 0.3s',
  //     }
  //   },
  // },
  // // focusVisible: {},
  

  export default NoiseReportForm;