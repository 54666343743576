import React, { useRef, useMemo, useCallback, useEffect, useState, createContext, useContext, useLayoutEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/styles';




export const WideButton = ({ sx, ...props }) => {
  return <Button sx={{
    margin: '1em auto 1.5em',
    width: '90%',
    borderRadius: '20px',
    ...sx,
  }} variant='contained' {...props} />
}


const useTagList = (initialValue) => {
    const [ tags, setTags ] = useState(initialValue || []);
    const addTag = (x) => {
        x = x.toLowerCase()
        return tags && tags.includes(x) ? tags : setTags([ ...tags, x ])
    }

    const removeTag = (x) => {
        x = x.toLowerCase()
        return tags.includes(x) ? setTags(tags.filter(xi => xi !== x)) : tags
    }
    return [ tags, addTag, removeTag, setTags ]
}


const TagContext = createContext();
const useCustomTags = () => useContext(TagContext)

export const TagProvider = ({ key='sonyc:tags', defaultValue=[], children }) => {
    const [ tags, addTag, removeTag, setTags ] = useTagList(() => {
        const x = window.localStorage.getItem(key);
        try {
          return x != null ? JSON.parse(x) : [];
        } catch (e) { 
          window.localStorage.setItem(key, '[]'); 
          return []; 
        }
    })

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(tags || []));
    }, [ key, tags ]);

    return <TagContext.Provider value={[ tags, addTag, removeTag, setTags ]}>
        {children}
    </TagContext.Provider>
}


export const TagsInput = ({ available, selected, setTag }) => {
  const [ custom, addTag, removeTag ] = useCustomTags();
  const [ value, setValue ] = useState('');

  const submitTag = tag => {
    tag = tag.toLowerCase();
    available.includes(tag) || addTag(tag);
    setTag(tag, true);
    setValue('');
  }

  return <Box>
    <TagGroup tags={available} selected={selected} setTag={setTag} />
    <TagGroup tags={[ ...custom ]} selected={selected} setTag={setTag} deleteTag={t => { removeTag(t); setTag(t, false) }} includeExtrasNotIn={available} />

    <TextField label="Add tags..."
      helperText="Press enter to add your tag."
      size="small" 
      value={value} onChange={e => setValue(e.target.value)}
      sx={{ width: '100%' }}
      InputProps={{endAdornment: 
        <IconButton onClick={() => submitTag(value)}><AddIcon fontSize='small' /></IconButton>
      }}
      onKeyUp={(e) => { if (e.key === 'Enter') submitTag(value) }} />
  </Box>
}

export const TagGroup = ({ tags, selected, title, setTag, deleteTag, includeExtrasNotIn }) => {
  const theme = useTheme();
  selected = selected || [];
  if(tags && includeExtrasNotIn) {
    includeExtrasNotIn = [ ...tags, ...includeExtrasNotIn ]
    tags = [ ...tags, ...selected.filter(t => !includeExtrasNotIn.includes(t)) ]
  }
  return (tags && tags.length ? <>
    {title && <Typography variant='h6'>{title}</Typography>}
    <Stack direction="row" flexWrap='wrap' justifyContent='stretch' alignItems='center'>
      {tags.map((tag) => 
        <Button key={tag} size='small' onClick={() => { setTag(tag, !selected.includes(tag)) }}
          sx={{ 
            textTransform: 'lowercase', 
            padding: '1px 12px', 
            margin: '0 0.4em 0.4em 0', 
            flexGrow: 1,
            position: 'relative',
            '& 	.MuiIconButton-root': { 
              opacity: 0,
              pointerEvents: 'none'
            },
            '&:hover 	.MuiIconButton-root': { opacity: 0.7, pointerEvents: 'all' },
          }}
          variant={selected && selected.includes(tag) ? 'selected' : 'outlined'}>
            <span style={{ flexGrow: 1 }}>{tag}</span>
            {deleteTag && 
            // <IconButton  size="small" onClick={e => { e.stopPropagation(); deleteTag(tag) }}> 
              <CloseIcon  sx={{
                position: 'absolute',
                top: '50%',
                left: '100%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '100%',
                backgroundColor: theme.palette.background.default,
                transition: 'filter 0.2s ease-in-out',
                '&:hover': {
                  filter: 'invert(100%)'
                }
              }} fontSize='inherit'  onClick={e => { e.stopPropagation(); deleteTag(tag) }} />
            // </IconButton>
            }
        </Button>)}
        <span style={{ flexGrow: 100, flexBasis: '100px' }}></span>
    </Stack>
  </> : null)
}
