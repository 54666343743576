import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
// import * as d3 from 'd3-fetch'

import { styled } from '@mui/styles';

import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxMap, { Marker, useControl } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';

import { useKeycloak } from 'react-kcfetch';
import useSWR from 'swr';

// import TextField from '@material-ui/core/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button'
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import HomeIcon from '@mui/icons-material/Home';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

// import Autocomplete from '@material-ui/lab/Autocomplete'
// import CloseIcon from '@material-ui/icons/Close';

// import blueMarker from '../assets/markers/blue-marker.svg';
// import redMarker from '../assets/markers/red-marker.svg';
// import yellowMarker from '../assets/markers/yellow-marker.svg';
// import greenMarker from '../assets/markers/green-marker.svg';
// import pinkMarker from '../assets/markers/pink-marker.svg';
// import { ReactComponent as BlackMarker } from "../assets/markers/marker.svg";
import { MarkerIcon, LocationIcon } from '../icons';

// import locIcon from '../assets/icons/user-icon.svg';
// import userIcon from '../assets/markers/pink-marker.svg';

import MapFocusCard from '../components/MapFocusCard';

import { AutocloseSnackbar } from '../components/utils';
import { pascalCase, useDeploymentId, useKeycloakJSONSWR, useLocalStore } from '../utils';
import { PinpointIcon } from '../icons'
import { 
    fetchGeocode, fetchAHVs, 
    fetchComplaints, fetchPermits, MONTH,
    placeholderFocus, placeNameToAddress
} from '../queries';
import { useTheme } from '@mui/styles';
// import { useTheme } from '@mui/styles';
import { mapStyleUrl, mapDarkStyleUrl, apiUrl, mapboxToken } from '../config'

/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from 'mapbox-gl';
import { Box, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;



// const outlineColor = '#231f20'
const outlineColor = '#fff';
const markerStyles = {
    blue: { fill: '#506e9c' },  // , outlineColor
    green: { fill: '#00a49a' },  // , outlineColor
    yellow: { fill: '#fab915' },  // , outlineColor
    pink: { fill: '#f16c93' },  // , outlineColor
    red: { fill: '#d82354' },  // , outlineColor
    grey: { fill: '#a3abbd' },  // , outlineColor
    white: { fill: '#fff', outlineColor: '#000' },  // , outlineColor
}


// const Mapbox = ReactMapboxGl({ accessToken });

let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const timeInside = (t, t0, t1) => {
    const dt = new Date() - (t||0);
    return t ? dt > t0 && dt < t1 : 0;
}

const useMapTouchEvents = ({ dropPin, timeout=200 }) => {
    const touchRef = useRef();
    const setTouchEvent = e => { 
        timeoutRef.current && clearTimeout(timeoutRef);
        touchRef.current = e
    }
    const timeoutRef = useRef();
    const dropPinLater = e => {
        timeoutRef.current = setTimeout(() => { 
            touchRef.current && dropPin(e) 
            setTouchEvent(false);
        }, timeout)
    }

    return {
        setTouchEvent,
        mapEvents: {
            onMouseDown: (e) => { 
                // console.log('onMouseDown', touchRef.current)
                !isMobile && setTouchEvent({ ...e, pressTime: new Date() }) },
            onTouchStart: (m,e) => { 
                // console.log('onTouchStart', touchRef.current)
                isMobile && setTouchEvent({ ...e, pressTime: new Date() }) },
            onMouseUp: (e) => { 
                // console.log('onMouseUp', touchRef.current, !isMobile, timeInside(touchRef.current?.pressTime, 100, 100000))
                !isMobile && timeInside(touchRef.current?.pressTime, 100, 100000) && touchRef.current && dropPinLater(e);
            },
            onTouchEnd: (e) => { 
                // console.log('onTouchEnd', touchRef.current, isMobile, timeInside(touchRef.current?.pressTime, 100, 100000))
                isMobile && timeInside(touchRef.current?.pressTime, 100, 100000) && touchRef.current && dropPinLater(e);
            },
            onDragStart: () => { 
                // console.log('onDragStart', touchRef.current)
                setTouchEvent(false) },
            onDragEnd: ()=>{ 
                // console.log('onDragEnd', touchRef.current)
                setTouchEvent(false) },
        }
    }
}

// const mapBoundsClose = (lat, lng, dist) => {
//     const [ minLat, maxLat, minLng, maxLng ] = mapBounds(lat, lng, dist);
//     return ( lat > minLat && lat < maxLat && lng > minLng && lng < maxLng )
// }
const coordShift = (x, dx) => parseFloat((parseFloat(x) + dx).toFixed(6))
const mapBounds = (lat, lng, dist) => [
    coordShift(lat, -dist),
    coordShift(lat,  dist),
    coordShift(lng, -dist),
    coordShift(lng,  dist),
]

// const SOMEWHERE_IN_NYC = [-73.957750, 40.673450]
const SOMEWHERE_IN_NYC = { longitude: -73.957750, latitude: 40.673450 }

const MapView = ({ 
        setLocation, currentUser,
        confirmLocation,
        currentRecord,
        ...props
}) => {
    const deployId = useDeploymentId();
    
    let [ userLoc, setUserLoc ] = useLocalStore('sonyc:user-location');
    const [ changingLoc, setChangingLoc ] = useState();
    
    // legacy
    useEffect(() => { Array.isArray(userLoc) && setUserLoc(null); }, [ userLoc, setUserLoc ])

    let { data: deployment, error: _depError } = useKeycloakJSONSWR(
        !userLoc && deployId && `${apiUrl}/deployments/${deployId}`, 
        { refreshInterval: 0, revalidateOnFocus: false });
    if(_depError) console.error(_depError);

    console.log('deployment', deployment);

    const { lat, lng } = deployment?.location || {};
    userLoc = userLoc || (!isNaN(lat) && !isNaN(lng) ? { coordinates: { latitude: lat, longitude: lng }, sonycType: 'home' } : null);

    console.log('user location:', userLoc)

    useEffect(() => { !currentRecord && userLoc && setLocation(userLoc) }, [ currentRecord, userLoc, setLocation ])
    return (
        !deployment && !userLoc && !changingLoc ? null : (changingLoc || !userLoc || userLoc === true ? 
            <UserLocationPrompt 
                placeholder='Enter your address:' 
                proximity={SOMEWHERE_IN_NYC}
                setUserLocation={loc => { setUserLoc(loc); setChangingLoc(false) }}
                cancelGetLocation={changingLoc && (() => setChangingLoc(false))} />
            : <>
            <Map setLocation={setLocation} userLoc={userLoc} unsetUserLoc={() => {
                setChangingLoc(true);
            }} confirmLocation={confirmLocation} {...props} />
            </>
    ))
}

const Map = ({ 
        setLocation, userLoc, currentUser,
        confirmLocation,
        // permitQueryDist=0.002000,
        permitQueryDist=0.002000,
        showOpeningDialog=true,
        unlocalizedZoom=11,
        localizedZoom=16,
        defaultCenter=SOMEWHERE_IN_NYC,
        unsetUserLoc,
        form,

}) => {
    const { noisetype } = form || {};
    const { initialized } = useKeycloak();
    const theme = useTheme();
    const mapRef = useRef();
    const deployId = useDeploymentId();

    const [showModal, setShowModal] = useState(true);

    const setCenter = ({ longitude, latitude }, zoom) => {
        mapRef.current && mapRef.current.flyTo({ center: [longitude, latitude], ...(zoom ? {zoom} : {}) })
    }

    // focus
    const [focus, setFocus] = useState(null);
    const [droppedPin, setDroppedPin] = useState(null)

    const [showSites, setShowSites] = useState(false);

    // Get Deployment Info
    /////////////////////////

    let userCoords = userLoc?.coordinates;
    userCoords = isNaN(userCoords?.latitude) || isNaN(userCoords?.longitude) ? null : userCoords;
    const { longitude, latitude  } = userCoords || {};
    const locBounds = useMemo(() => 
        !isNaN(latitude) && !isNaN(longitude) && mapBounds(latitude, longitude, permitQueryDist), 
        [ longitude, latitude, permitQueryDist ])
    
    // Previous Reports
    //////////////////////

    
    let { data: reports, error: _repError } = useKeycloakJSONSWR(
        initialized && deployId && `${apiUrl}/reports/${deployId}`, 
        { refreshInterval: 0, revalidateOnFocus: false });
    if(_repError) console.error(_repError);

    // legacy
    reports = useMemo(() => (
        !reports ? null : reports.map(({ startTime, endTime, duration, location, record, ...d }) => ({
            ...record,
            ...d, 
            startTime: Date.parse(startTime || duration?.[0]),
            endTime: Date.parse(endTime || duration?.[1]),
            sonycType: 'user',
            unique_id: d._id,
        })).sort((a, b) => a.endTime - b.endTime)
    ), [ reports ])

    // userLoc = useMemo(() => userLoc || (reports ? reports.find(r => r.location).location : null))

    // AHVs
    ///////////

    const isConstruction = (!noisetype || noisetype === 'Construction');

    const { data: ahvPermitsAll, error: _ahvError } = useSWR(
        showSites && isConstruction && [], fetchAHVs, { refreshInterval: 0, revalidateOnFocus: false })
    if(_ahvError) console.error(_ahvError)

    const ahvPermits = useMemo(() => {
        if(!locBounds) return;
        const [ minLat, maxLat, minLng, maxLng ] = locBounds;
        return ahvPermitsAll
         && ahvPermitsAll.filter(({ coordinates: { latitude, longitude } }) => (
            latitude > minLat && latitude < maxLat
            && longitude > minLng && longitude < maxLng
        ))
    }, [ locBounds, ahvPermitsAll ])

    // DOB
    ////////////

    const { data: dobSites, error: _sitesError } = useSWR(
        showSites && isConstruction && locBounds, fetchPermits, 
        { refreshInterval: 0, revalidateOnFocus: false })
    if(_sitesError) console.error(_sitesError)

    // 311
    ///////////

    const queryTime = useMemo(() => new Date(Date.now() - MONTH), [])

    const { data: complaints, error: _compError } = useSWR(
        showSites && locBounds && [ queryTime, noisetype, ...locBounds ], fetchComplaints, 
        { refreshInterval: 0, revalidateOnFocus: false })
    if(_compError) console.error(_compError)


    const focusSummary = ({ type, subType, sonycType, startTime, endTime }) => {
        return (
            sonycType === 'DOB' ? `DOB Permit: ${type} (${subType})` : 
            sonycType === '311' ? `311: ${type} (${subType}) - ${new Date(startTime).toLocaleDateString([], { day: 'numeric', month: 'numeric' })}` : 
            null
        )
    }

    // debug
    useEffect(() => { ahvPermits && console.log('AHV Permits close to you:', ahvPermits) }, [ ahvPermits ])
    useEffect(() => { complaints && console.log('complaints close to you:', complaints) }, [ complaints ])
    useEffect(() => { dobSites && console.log('DOB Permits close to you:', dobSites) }, [ dobSites ])
    useEffect(() => { console.log('Your user reports:', reports) }, [ reports ])
  
    const setFocusLocation = (focus, { center=false }={}) => {
        setFocus(focus)
        if(!focus) { 
            setLocation && setLocation({ ...userLoc, sonycType: 'user' }); 
            return; 
        }
        // const { address, coordinates, sonycType, unique_id } = focus;
        // setLocation && setLocation({ address, coordinates, sonycType, unique_id })
        setLocation && setLocation(focus)
        center && setCenter(focus.coordinates)
    }
  
    const dropPin = ({ lngLat: { lng: longitude, lat: latitude }={} }={}, options={}) => {
        setDroppedPin({ longitude, latitude })
        setFocus(placeholderFocus({ longitude, latitude }))
        fetchGeocode(longitude, latitude).then((focus) => {
          setDroppedPin(null)
          setFocusLocation(focus, { center: false })
        })
    };
  
  
    const { setTouchEvent, mapEvents } = useMapTouchEvents({ dropPin });
    

    const markerClickHandler = (d) => {
        d = d.record ? { ...d.record, permits: d.permits, duration: d.duration } : d;
        return e => {
            console.log('click', d)
            setTouchEvent(false)
            setFocusLocation(d);
        }
    }

    defaultCenter = userCoords || defaultCenter;
    return (<>
       <MapboxMap 
           mapStyle={theme.palette.mode === 'dark' ? mapDarkStyleUrl : mapStyleUrl} ref={mapRef}
           mapboxAccessToken={mapboxToken}
           style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, position: 'relative', width: '100%', height: '100%' }}
           initialViewState={{ ...defaultCenter, zoom: userCoords ? localizedZoom : unlocalizedZoom }}
           {...mapEvents}
           attributionControl={false}>
           {/* <GeocoderControl mapboxAccessToken={mapboxToken} 
             position="top-left" marker={false} placeholder='Enter an address:'
             onResult={({ result: { place_name, geometry: { coordinates: [ longitude, latitude ] }={} }={} }) => {
               if(longitude !== null && latitude !== null) {
                 setCenter([ longitude, latitude ]);
                 // setDroppedPin({ longitude, latitude });
               }
               setFocusLocation({
                 address: placeNameToAddress(place_name),
                 dates: {},
                 coordinates: { longitude, latitude },
                 sonycType: 'search',
               })
             }} /> */}

           {/* {currentUser && 
               <CustomMarker 
                   longitude={currentUser.location[0]} latitude={currentUser.location[1]} 
                   src={locIcon} alt='user location' />} */}         

           {/* {userLoc.coordinates && 
               <CustomMarker 
                   {...userLoc.coordinates}
                   component={LocationIcon} alt='user location' size='25px'
                   tooltip='Me' />} */}
            {userLoc.coordinates && 
               <CustomMarker 
                   {...userLoc.coordinates}
                   icon={<HomeIcon />} alt='user location' size='25px'
                   tooltip='Me' />}

           {ahvPermits && ahvPermits.map(p => 
               <CustomMarker key={p.uid}
                   {...p.coordinates}
                   {...markerStyles['blue']} alt='ahv permit' />)}

           {dobSites && dobSites.map((p, i) => 
               <CustomMarker {...p.coordinates} 
                   onClick={markerClickHandler(p)} key={p.uid}
                   {...markerStyles['green']} alt='DOB Permit'
                   tooltip={focusSummary(p)} />)}

           {complaints && complaints.map((p, i) => 
               <CustomMarker {...p.coordinates} key={p.uid}
                   onClick={markerClickHandler(p)}
                   {...markerStyles['yellow']} alt='311 Complaint'
                   tooltip={focusSummary(p)} />)}

           {reports && reports.map((p, i) => 
        //    longitude={p.location?.[0]} latitude={p.location?.[1]}
                <CustomMarker {...p.coordinates} key={p._id}
                    onClick={markerClickHandler(p)}
                    {...markerStyles['pink']} alt='Your Report'
                    tooltip={focusSummary(p)} />)}

           {/*  */}

           {focus ? 
               <CustomMarker 
                   {...focus.coordinates}
                   {...markerStyles['white']} alt='selected pin' size='33px'
                   tooltip={focusSummary(focus)} zIndex={3} />
           : droppedPin ? 
               <CustomMarker 
                   longitude={droppedPin.lng} latitude={droppedPin.lat} 
                   {...markerStyles['white']} alt='dropped pin' zIndex={3} /> 
           : null}

           {focus && <MapFocusCard permit={focus} setFocus={setFocusLocation} confirmLocation={confirmLocation} />}

           {<InstructionModal showModal={showModal} setShowModal={setShowModal} />}
           {/* {queryingUserLoc && (queryingUserLoc === true ? 
               <AutocloseSnackbar message="Getting your location..." /> : 
               <AutocloseSnackbar autoHideDuration={9000} message={`Error getting your location: ${queryingUserLoc.message}`} />)} */}
           </MapboxMap>
           <Stack direction='row' sx={{ alignSelf: 'flex-end' }}>
               <Tooltip title='Show sites that may be causing noise'>
                <Button variant={showSites?'selected-text':'text'} onClick={() => setShowSites(!showSites)}>311</Button>
               </Tooltip>
               <Tooltip title='Zoom back to my home'>
                 <Button onClick={() => setCenter(userCoords, localizedZoom)}>Center Home</Button>
               </Tooltip>
               <Tooltip title='Correct my home address'>
                <Button onClick={() => unsetUserLoc()}>my address</Button>
               </Tooltip>
            </Stack>
    </>)
}


const UserLocationPrompt = ({ geocodeCurrentLocation=true, setUserLocation, cancelGetLocation, ...props }) => {
    const theme = useTheme();
    const [ location, setLocation ] = useState();
    const { coordinates, address } = location || {};

    const onResult = useCallback(({ result: { center: [ longitude, latitude ], place_name } }) => {
        console.log('result:', longitude, latitude, place_name);
        setLocation({ coordinates: { longitude, latitude }, address: placeNameToAddress(place_name) });
    }, [ setLocation ])

    const [ geocoder, setElement ] = useGeocoder({ 
        accessToken: mapboxToken, ...props, 
        onResult,
    });
    const { longitude, latitude } = coordinates || {}

    const { requested, error, request } = useUserLocation({
        onSuccess: ({ coords }) => {
            setLocation({ coordinates: coords }) //, accessTime: timestamp
        }, onError: console.error
    });

    useEffect(() => {
        if(!geocodeCurrentLocation || isNaN(longitude) || isNaN(latitude)) return;
        fetchGeocode(longitude, latitude).then(({ address, coordinates }) => {
            setLocation({ address, coordinates })
        })
    }, [ longitude, latitude, geocodeCurrentLocation ])

    return <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' padding='2rem' textAlign='center' sx={{ 
            width: '100%', height: '100%',
            borderRadius: '25px',
            backgroundImage: theme.palette.gradients.warmBackground,
            //'linear-gradient(47deg, rgb(207 209 255 / 59%) 0%, rgb(144 54 255 / 19%) 100%)',
        }}>
        <Typography variant='h3' gutterBottom>
            Find noise complaints in your area.
        </Typography>
        <Typography variant='h6' gutterBottom>
            Let's see if we can associate a city record with this report. 
        </Typography>
        <MapboxGeocoderContainer ref={setElement} />
        <Stack pt={3} spacing={1}>
            <Button variant='outlined' disabled={requested} onClick={request}>{requested ? 'Searching...' : 'Use Device Location'}</Button>
            {error && <Alert severity="error">[{error.code}] {error.message}</Alert>}
            {address ? <Alert severity="success"><code>{address.house_num} {address.street}</code></Alert>
             : coordinates ? <Alert severity="success"><code>[{latitude}, {longitude}]</code></Alert> : null}
            <Button variant='contained' disabled={!coordinates} onClick={() => setUserLocation(location)}>
                Confirm
            </Button>
            {cancelGetLocation && <Button onClick={() => cancelGetLocation()}>Cancel</Button>}
            
            {/* <Button>Skip</Button> */}
        </Stack>
        
        
    </Box>
}

const MapboxGeocoderContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .mapboxgl-ctrl-geocoder': { 
        width: '100%', 
        maxWidth: '600px',
        fontSize: '0.85rem',
    },
})

const useCtlEvent = (geocoder, name, event) => {
    useEffect(() => {
        if(name && event) {
            geocoder.on(name, event);
            return () => { geocoder.off(name, event); }
        }
    }, [ geocoder, name, event ])
}
const useCtlProp = (geocoder, k, v) => {
    useEffect(() => {
        const p = pascalCase(k);
        if (v !== undefined && geocoder[`get${p}`]() !== v) {
            geocoder[`set${p}`](v);
        }
    }, [ geocoder, k, v ])
}

const useGeocoder = ({ accessToken, ...props }) => {
    const geocoder = useMemo(() => new MapboxGeocoder({ accessToken }), [ accessToken ]);
    useCtlEvent(geocoder, 'loading', props.onLoading)
    useCtlEvent(geocoder, 'results', props.onResults)
    useCtlEvent(geocoder, 'error', props.onError)
    useCtlEvent(geocoder, 'result', props.onResult)
    useCtlProp(geocoder, 'proximity', props.proximity)
    useCtlProp(geocoder, 'renderFunction', props.renderFunction)
    useCtlProp(geocoder, 'language', props.language)
    useCtlProp(geocoder, 'zoom', props.zoom)
    useCtlProp(geocoder, 'flyTo', props.flyTo)
    useCtlProp(geocoder, 'placeholder', props.placeholder)
    useCtlProp(geocoder, 'countries', props.countries)
    useCtlProp(geocoder, 'types', props.types)
    useCtlProp(geocoder, 'minLength', props.minLength)
    useCtlProp(geocoder, 'limit', props.limit)
    useCtlProp(geocoder, 'filter', props.filter)
    useCtlProp(geocoder, 'origin', props.origin)
    useCtlProp(geocoder, 'autocomplete', props.autocomplete)
    useCtlProp(geocoder, 'fuzzyMatch', props.fuzzyMatch)
    useCtlProp(geocoder, 'routing', props.routing)
    useCtlProp(geocoder, 'worldview', props.worldview)

    const element = useMemo(() => geocoder.onAdd(), [ geocoder ])
    const [ parent, setParent ] = useState();
    useEffect(() => {
        if(parent && element) {
            parent.appendChild(element);
            return () => { parent.removeChild(element); }
        }
    }, [ parent, element ])
    return [ geocoder, setParent ]
}

const useUserLocation = ({ auto, onSuccess, onError }={}) => {
    const [ userLoc, setUserLoc ] = useState();
    const [ requested, setRequested ] = useState(auto);
    const [ error, setError ] = useState();

    useEffect(() => {
        if(!requested) return;
        let unmounted;
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude, accuracy }={}, timestamp }) => {
                if(unmounted) return;
                let d = { coords: { latitude, longitude, accuracy }, timestamp };
                console.log('got user location', d);
                setError(null)
                setUserLoc(d)
                setRequested(false)
                onSuccess(d)
            }, 
            ({ message, code }) => { 
                if(unmounted) return;
                let e = { message, code };
                console.error('error getting user location', e);
                setError(e); 
                setRequested(false); 
                onError(e) 
            },
            {  }); //timeout: 20 * 1000, maximumAge: 1000
        return () => { unmounted = true }
    }, [ requested, onSuccess, onError ])

    return { ...userLoc, error, requested, request: (() => setRequested(true)) };
}


/* eslint-disable complexity,max-statements */
const GeocoderControl = props => {
  const [marker, setMarker] = useState(null);
  const { 
      mapboxAccessToken: accessToken, position, markerProps,
      onLoading, onResults, onResult, onError } = props;
    // console.log('geo', accessToken, props)

  const geocoder = useControl(({ map, mapLib }) => {
      const ctrl = new MapboxGeocoder({ ...props, accessToken, mapboxgl: mapLib });
      onLoading && ctrl.on('loading', onLoading);
      onResults && ctrl.on('results', onResults);
      onError && ctrl.on('error', onError);
      ctrl.on('result', evt => {
        onResult && onResult(evt);
        // const { result: { center, geometry: { type, coordinates }={} }={} } = evt;
        // const [ lng, lat ] = center || (type == 'Point' && coordinates) || [null, null]
        // setMarker(lng && lat ? <Marker {...markerProps} longitude={lng} latitude={lat} /> : null);
      });
      return ctrl;
    }, { position }
  );

  const useProp = (n, alt) => {
    const v = props[n];
    useEffect(() => {
      if (!geocoder._map) return;
      if (v !== undefined && geocoder[`get${pascalCase(alt || n)}`]() !== v) {
        geocoder[`set${pascalCase(alt || n)}`](props);
      }
    }, [ v, n, alt ])
  }
  useProp('proximity')
  useProp('render', 'renderFunction')
  useProp('language')
  useProp('zoom')
  useProp('flyTo')
  useProp('placeholder')
  useProp('countries')
  useProp('types')
  useProp('minLength')
  useProp('limit')
  useProp('filter')
  useProp('origin')
  useProp('autocomplete')
  useProp('fuzzyMatch')
  useProp('routing')
  useProp('worldview')

  return marker;
}

GeocoderControl.defaultProps = {
  onLoading: (...x) => {}, // console.log('onLoading', ...x)
  onResults: (...x) => {}, // console.log('onResults', ...x)
  onResult: (...x) => {}, // console.log('onResult', ...x)
  onError: (...x) => {}, // console.log('onError', ...x)
};


const CustomMarker = ({ latitude, longitude, component, icon, alt, fontSize='medium', tooltip, zIndex, style, fill='#000', outlineColor='#fff', ...p }) => {
    // const theme = useTheme();
    latitude = parseFloat(latitude);
    longitude = parseFloat(longitude);
    return latitude && longitude ? (
            <Marker longitude={longitude} latitude={latitude} anchor="bottom" style={{ ...style, zIndex }} {...p}>
                <Tooltip title={tooltip || ''}  placement="top">
                    {icon || <SvgIcon inheritViewBox fontSize={fontSize} sx={{ 
                        ...(!component ? {
                            '.marker-fill': { fill: fill },
                            '.marker-outline': { fill: outlineColor },
                        } : null)
                    }} component={component || MarkerIcon}/>}
                {/* <SvgIcon style={{ display: 'block', margin: '1em auto 0 auto', width: size }} component={MarkerIcon}/> */}
                    {/* <img src={src} alt={alt} width={size} /> */}
                    
                </Tooltip>
            </Marker>
    ) : null;
}


const InstructionModal = ({ showModal, setShowModal }) => {
    return (
        <Dialog open={showModal} onClose={() => setShowModal(false)} onKeyUp={(e) => { (e.key === 'Enter') && setShowModal(false) }}>
            <div style= {{width: '100%'}}>
                <SvgIcon viewBox="0 0 34 34" style= {{display: 'block', margin: '1em auto 0 auto', width: '3em', height: '3em'}} component={PinpointIcon}/>
            </div>
            <div style={{padding: '0.5em', textAlign: 'center' }}>Press on the map to drop a pin at the location the noise is coming from.</div>
            {/* <div style={{padding: '1.5em', textAlign: 'center' }}>
                The app will attempt to get your current location which may take a few seconds. We use 
                this to find 311 noise complaints around your area.
            </div> */}
            <div style={{padding: '0.5em 0.5em 1.5em', textAlign: 'center' }}>
                Your previous reports are shown in pink.
                <SvgIcon inheritViewBox fontSize='small' sx={{ 
                        '.marker-fill': { fill: markerStyles['pink'].fill },
                        '.marker-outline': { fill: outlineColor },
                    }} component={MarkerIcon} />
            </div>
            <Button onClick={() => setShowModal(false)}>Ok</Button>
        </Dialog>
    )
}



// const useUserLocation = (updateLocation) => {
//     const [ coordsGot, setCoordsGot ] = useState(false);
//     // user location
//     const [ savedUserLoc, setSavedUserLoc ] = useLocalStore('sonyc:user-location');  // persist last location
//     const initialUserLoc = useRef(savedUserLoc)  // get the initial value so that it doesn't change
//     let [ userLoc, setUserLoc ] = useState(null);  // freshly retrieved location
//     // messages
//     const [queryingUserLoc, setQueryingUserLoc] = useState(true)

//     useEffect(() => {
//         if (coordsGot) return;
//         if (!navigator.geolocation) {
//             setQueryingUserLoc({ message: "Geolocation is not supported by this browser.", code: 0 })
//             console.error("Geolocation is not supported by this browser.");
//             return;
//         }
//         let unmounted = false;
//         const queryLocation = () => {
//             return new Promise((resolve, reject) => {
//                 console.log('querying user location...')
//                 navigator.geolocation.getCurrentPosition(
//                     ({ coords: { latitude, longitude }={} }={}) => {
//                         if(unmounted) return;
//                         console.log('Got user location!', [longitude, latitude])
//                         setUserLoc([longitude, latitude])
//                         setSavedUserLoc([longitude, latitude])
//                         setCenter([longitude, latitude], localizedZoom)
//                         setLocation && setLocation({ location: [longitude, latitude] })
//                         setQueryingUserLoc(false)
//                         resolve([longitude, latitude])
//                     }, 
//                     (e) => { reject(e) },
//                     { timeout: 20 * 1000, maximumAge: 1000 });//15 * 60 * 
//             })
//         }

//         setQueryingUserLoc(true)
//         queryLocation().catch(e => queryLocation().catch(e => {
//             if(unmounted) return;
//             console.error("Error getting user location", e);
//             setQueryingUserLoc(e);
//         }))

//         setCoordsGot(true);
//         return () => { unmounted = false }
//     }, [ coordsGot, setLocation, localizedZoom, setSavedUserLoc ])


//     userLoc = userLoc || (reports?.length ? reports[0] : null);

//     // handle default user location/map center
//     userLoc = userLoc || savedUserLoc || (reports?.length ? reports[reports.length - 1].location : null);
//     if(!userLoc || isNaN(userLoc?.[0]) || isNaN(userLoc?.[1])) { userLoc = null; }
    
//     userLoc = useMemo(() => {
//         if(!userLoc && reports) {
//             const loc = reports?.length ? reports[reports.length - 1].location : null;
//             loc && setCenter(loc, localizedZoom)
//             return loc || userLoc;
//         }
//         return userLoc;
//     }, [ userLoc, reports, localizedZoom ])

//     defaultCenter = userLoc || defaultCenter;
    
//     // this prevents the reports from refreshing if the new location is close enough
//     let queryCenter = null;
//     queryCenter = useMemo(() => {
//         const saved = initialUserLoc.current;
//         if(userLoc && saved) {
//             if(Math.abs(userLoc[0] - saved[0]) < permitQueryDist / 3
//                 && Math.abs(userLoc[1] - saved[1]) < permitQueryDist / 3) {
//                 console.log("keeping previously saved user location for queries since it's close enough to the current location.")
//                 return saved;
//             }
//         }
//         return userLoc || saved;
//     }, [ userLoc, permitQueryDist ])
//     // console.log(queryCenter)

//     return {
//         userLoc, savedUserLoc, coordsUpdate
//     };
// }

export default MapView;