import * as d3 from 'd3-fetch';
// import distance from '@turf/distance';
import { mapboxToken, nycodToken, permitTypes, bisJobCodes, dobNowCodes, noiseTypeKeys } from './config'



export const fetchPermits = (minLat, maxLat, minLng, maxLng) => {
    if(isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng)) {
        throw Error("NaN location bounds");
    }
    const dobUrl = 'https://data.cityofnewyork.us/resource/ipu4-2q9a.json?$where=';
    const dobQuery = `gis_latitude between "${minLat}" and "${maxLat}" AND gis_longitude between "${maxLng}" and "${minLng}"`
    const url = dobUrl + encodeURIComponent(dobQuery)
    // console.log(dobQuery, url, [minLat, maxLat, minLng, maxLng])
    return fetch(url, { method: 'GET', data: { '$limit' : 20, '$$app_token': nycodToken } })
        .then(res => res.json())
        .then(data => {
            const sites = {};
            data.forEach(permit => {
                if (!(new Date(permit.expiration_date) > Date.now() && new Date(permit.job_start_date) < Date.now())){
                    return;
                }
                
                const p = {
                    bin: permit.bin__,
                    permitNumber: permit.permit_si_no,
                    startTime: permit.job_start_date,
                    endTime: permit.expiration_date,
                    // dates: { start: permit.job_start_date, end: permit.expiration_date },
                    type: permitTypes[permit.permit_type] || permit.permit_type,
                    subType: permitTypes[permit.job_type] || permit.job_type,
                }
                const site = sites[p.bin] || { 
                    sonycType: 'DOB',
                    uid: p.bin, permits: [],
                    address: { 
                        city: permit.city || "New York", state: 'NY', zip: permit.zip_code,
                        onStreet: null, crossStreet1: null, crossStreet2: null,
                    },
                    coordinates: { longitude: permit.gis_longitude, latitude: permit.gis_latitude },
                    
                };
                site.permits.push(p);
                sites[p.bin] = { ...site, ...p }
            })
            return Object.values(sites);
        })
}


const checkContains = (s, sub) => s && s.toLowerCase().includes(sub)

export const MONTH = 1000 * 60 * 60 * 24 * 30;

export const fetchComplaints = (complaintDate, noiseType, minLat, maxLat, minLng, maxLng) => {
    if(isNaN(minLat) || isNaN(maxLat) || isNaN(minLng) || isNaN(maxLng)) {
        throw Error("NaN location bounds");
    }
    complaintDate = complaintDate || new Date(Date.now() - MONTH);
    // actually a method for formatting this is built into js...
    let complaintDateString = `${complaintDate.getFullYear()}-${(complaintDate.getMonth()+1).toString().padStart(2, "0")}-${complaintDate.getDate().toString().padStart(2, "0")}T00:00:00.000`

    let complaintUrl = 'https://data.cityofnewyork.us/resource/erm2-nwe9.json?$where='
    let complaintQuery = `latitude between "${minLat}" and "${maxLat}" AND longitude between "${minLng}" and "${maxLng}" AND created_date > "${complaintDateString}"`
    let url = complaintUrl + encodeURIComponent(complaintQuery)

    console.log(url, complaintQuery)
    return fetch(url, { method: 'GET', data: { '$limit' : 20, '$$app_token': nycodToken } })
        .then(res => res.json())
        .then(data => {
            // console.log('raw complaint', data)
            const noiseKeywords = noiseTypeKeys[noiseType];

            const dataIndex = {}
            for(let d of data) {
                const { 
                    city, incident_zip, created_date, longitude, latitude,
                    street_name, cross_street_1, cross_street_2,
                    complaint_type, descriptor, unique_key, status,
                    incident_address,
                } = d;
                
                if(!(
                    checkContains(complaint_type, 'noise') 
                    || checkContains(descriptor, 'noise')
                    // && noiseKeywords.some( kw => complaint.complaint_type?.toLowerCase().includes(kw) || complaint.descriptor?.toLowerCase().includes(kw)))
                )) { continue; }
                

                const [ house_num, ...streetParts ] = incident_address ? incident_address.split(' ') : [null];
                const street = streetParts.join(' ');

                dataIndex[unique_key] = ({
                    sonycType: '311', 
                    uid: unique_key, 
                    type: complaint_type, 
                    subType: descriptor,
                    status,
                    
                    startTime: created_date,
                    endTime: created_date,
                    time: created_date,
                    // dates: { start: null, end: null, incident: created_date },
                    coordinates: { longitude, latitude },
                    address: { 
                        house_num, street,
                        city: city || "New York", state: 'NY', zip: incident_zip, 
                        onStreet: street_name, 
                        crossStreet1: cross_street_1, 
                        crossStreet2: cross_street_2,
                    },
                    
                })
            }
            return Object.values(dataIndex);
        })
}

const yesNo = x => ['y', 'yes'].includes((x||'').toLowerCase())

export const fetchAHVs = () => { //center, dist=0.002000
    let url = 'https://raw.githubusercontent.com/NYCDOB/ActiveAHVs/gh-pages/data/activeAHVs.csv'
    return d3.csv(url).then(data => {
        let ahvList = {}

        // const bounds = center ? mapBounds(center[1], center[0], dist) : null;
        // const [ minLat, maxLat, minLng, maxLng ] = bounds || [0,0,0,0];
        // const inBounds = (longitude, latitude) => {
        //     return bounds ? (
        //         latitude > minLat && latitude < maxLat
        //         && longitude > minLng && longitude < maxLng
        //     ) : true;
        // }

        data.forEach((ahv) => { 
            const [ house_num, ...streetParts ] = ahv.Address?.split(' ') || [null];
            const street = streetParts.join(' ');
            const uid = ahv.BIN;
            const latitude = parseFloat(ahv.Lon);
            const longitude = parseFloat(ahv.Lat);

            // if(!inBounds(longitude, latitude)) {
            //     return;
            // }

            ahvList[uid] = {
                uid,
                sonycType: 'AHV',
                type: permitTypes[ahv["Work Type"]] || ahv["Work Type"] || null, 
                // subType: descriptor,
                status: ahv["Record Status Description"],

                startTime: ahv["Start Date"],
                endTime: ahv["End Date"],
                time: ahv["Start Date"],
                // dates: { start: ahv["Start Date"], end: ahv["End Date"] },
                coordinates: { latitude, longitude },
                address: { 
                    house_num, street,
                    city: ahv.Borough || "New York", state: 'NY', 
                },

                bin: ahv.BIN,
                communityBoard: ahv["Community Board"],
                jobNumber: ahv["Job Number"],
                refNumber: ahv["Reference Number"],
                filingType: ahv["Filing Type Description"],
                referenceIn200ft: yesNo(ahv["Residence within 200ft"]),
                inEnclosedBuilding: yesNo(ahv["Is Work Done n Enclosed Building"]),
                fullPartialDemolition: yesNo(ahv["Does Work Involve Full or Partial Demolition"]),
                craneUse: yesNo(ahv["Does WOrk Involve Crane Use"]),
                workType: ahv["Work Type"],
                source: ahv["Source"],
                weekendWork: ahv["Weekend Work"],
                reason: ahv["Reason"],
                minimalNoiseImpact: ahv["Reason"].toLowerCase().includes('minimal noise'),

                // pointOfContact: { 
                //     firstName: ahv["Point of Contact First Name"],
                //     lastName: ahv["Point of Contact Last Name"],
                //     phone: ahv["Point of Contact Mobile Phone"],
                // },
                // contractor: {
                //     name: ahv["Contractor Name"],
                //     business: ahv["Contractor Buisness Name"], // [sic]
                //     phone: ahv["Constractor Business Phone"],
                // },
            } 
        })
        return Object.values(ahvList);
    })
}

// {
//     "BIN": "1090642",
//     "Address": "821 1ST AVENUE",
//     "Borough": "Manhattan",
//     "Community Board": "106",
//     "Job Number": "121191138",
//     "Reference Number": "01041042",
//     "Filing Type Description": "Renewal",
//     "Record Status Description": "ISSUED",
//     "Point of Contact First Name": "STACOM",
//     "Point of Contact Last Name": "ANTHONY",
//     "Point of Contact Mobile Phone": "9176177566",
//     "Start Date": "3/11/2022",
//     "End Date": "3/24/2022",
//     "Contractor Name": "MILDRED         CLAIRE",
//     "Contractor Buisness Name": "TISHMAN CONSTRUCTION CORP",
//     "Constractor Business Phone": "2127397052",
//     "Residence within 200ft": "Y",
//     "Is Work Done n Enclosed Building": "Y",
//     "Does Work Involve Full or Partial Demolition": "N",
//     "Does WOrk Involve Crane Use": "N",
//     "Work Type": "NB",
//     "Source": "BIS",
//     "Weekend Work": "Yes",
//     "Reason": "PUBLIC SAFETY",
//     "Lat": "40.7513999938965",
//     "Lon": "-73.9685363769531"
// }


const geocodingUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'

export const fetchGeocode = (lng, lat) => {
    return fetch(`${geocodingUrl}${lng}%2C%20${lat}.json?access_token=${mapboxToken}`, {
        method: 'GET',
        data: { '$limit' : 20, '$$app_token' : nycodToken }})
        .then(res => res.json())
        .then(res => {
            // console.log(res.features[0])
            // works for "ADDRESS" needs updating for "POI"
            let poiAddress = res.features[0].place_name
            if(res.features[0].place_type[0] === "poi"){
                let poiAddress_ = res.features[0].place_name.split(',')
                poiAddress_.shift()
                poiAddress = poiAddress_.join(',')
            }
            return {
                // address: {house_num: '', street: 'PLACEHOLDER', city: '', state: '', zip:''},
                address: placeNameToAddress(poiAddress),
                coordinates: {longitude: lng, latitude: lat },
                // dates: {start: '', end: '', incident: ''},
                startTime: null,
                endTime: null,
                sonycType: 'pin',
                subType: '',
                type: '',
                uid: '',
            };
        })
}

export const fetchGeocodeSearch = ({ query, center }) => {
    return fetch(`${geocodingUrl}${query}.json?types=address,poi&bbox=-74.056590,40.554097,-73.724254,40.997116&access_token=${mapboxToken}`, {
        method: 'GET',
        data: { '$limit': 20, '$$app_token': nycodToken }})
    .then(res => res.json())
    .then(({ features }={}) => {
        // Crashes at Hudson Yards Chiropractor - "no token" ?
        // features = features.sort(({ center: a }, { center: b }) => 
        //     distance(a, center) - distance(b, center))
        return features
    })
}

export const placeNameToAddress = (name) => {
    const [ address, city, stateZip ] = name.split(',');
    const [ house_num, ...streetAddressSplit ] = address.split(' ');
    return {
        house_num, street: streetAddressSplit.join(' '),
        city, state: 'NY', zip: stateZip.split(' ').pop(),
        place_name: name,
        streets: {},
    };
}



// center: LAT LONG
// geometry: object with coordinates: [] and type: "Point"
// id: string "poi.xxxxxxxx" with number
export const makeSearchValue = ({ ...d }={}) => ({
    center: [], context: [], geometry: {}, id: "" ,
    place_type: ["poi"], properties: {}, relevance: 0.8,
    text: "TEST TEXT", type: "Feature",
    ...d
})

export const placeholderFocus = ({ lng, lat, coordinates, address, ...p }={}) => ({
    address: { house_num: '', street: '', city: '', state: '', zip: '', ...address },
    coordinates: {longitude: lng , latitude: lat, ...coordinates },
    // dates: { start: '', expiration: '', incident: '', ...dates },
    // permits: {},
    startTime: null,
    endTime: null,
    time: null,
    sonycType: 'placeholder',
    streets: {},
    subType: '',
    type: '',
    uid: '',
    ...p
})
