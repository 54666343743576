import React, { Fragment } from 'react';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SvgIcon from '@mui/material/SvgIcon';

// import { makeStyles } from '@mui/styles';

// import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom'

import { titleCase } from '../utils';
import { DobIcon, Nyc311Icon, DotIcon, SonycLogo, MarkerIcon } from '../icons';


import dobIcon from '../assets/logos/nycdob.svg'
import complaintIcon from '../assets/logos/nyc311.svg'
import dotIcon from '../assets/logos/nycdot.svg'
import SonycIcon from '../assets/SONYC_logo.svg'
import grayMarker from '../assets/markers/gray-marker.svg';


let iconTypes = {
    'DOB': DobIcon,
    'DOT': DotIcon,
    '311': Nyc311Icon,
    'user': SonycLogo,
    'search': SonycLogo,
    'pin': MarkerIcon,
    // 'search': grayMarker,
}

const SiteCard = ({ permit, ahvPermit, setFocus, confirmLocation }) => {
    const handleNext = console.log
    const { address, startTime, endTime, sonycType, duration, permits, type, subType } = permit;
    // console.log(permit)

    // DOB, DOT, 311, History
    // 
    // Fix DATE and TYPE / DESC
    // to depend on sonycType that comes back
    // generic card has date area and desc area filled depending

    // for DOB permits
    const formatDate = d => new Date(d).toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' })
    const formatDateRange = ({ startTime, endTime }) => `Permit: ${formatDate(startTime)} to ${formatDate(endTime)}`
    const formatTypeDesc = ({ type, subType, sonycType }) => `${type}: ${subType}`

    // for 311 complaints
    const formatIncidentDate = ({ sonycType, startTime }) => {
        if(sonycType === 'user'){
            const reportDate = new Date(startTime); //startTime || (duration && duration[0])
            if(isNaN(reportDate)) return null;
            const time = reportDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            const day = reportDate.toLocaleDateString([], { month: 'short', day: 'numeric' })
            return `Reported at ${time} on ${day}`;
        }
        return startTime ? `Reported: ${formatDate(startTime)}` : null;
    }

    

    // if (permit.sonycType === 'DOT'){
    //     return(<Card classes={{root : classes.root}}>
    //         <CardContent>
    //             <img src={iconTypes[permit.sonycType]} alt={`${permit.sonycType} icon`} width='12%'/>
    //             <Typography style={{fontWeight: 'bold'}}>On {permit.streets.onStreet}{permit.streets.crossStreet2 && ` between ${permit.streets.crossStreet2}` } and {permit.streets.crossStreet1}</Typography>
    //             <Typography><span style={{fontWeight:'normal'}}>{permit.address.city}, NY</span></Typography>
    //         </CardContent>
    //         </Card>)
    // }

    const Icon = iconTypes[sonycType];

    // city is breaking for Graham on Safari, corrected with 311 permits getting a default city of "New York"
    return(
        <Box display='flex' justifyContent='flex-end'>
        <Card elevation={5} sx={{
            minHeight: 125,
            margin: "1em 1em 1.4em 1em",
            borderRadius: 4,
            userSelect: 'none',
            zIndex: 100, flexGrow: 0,
            // position: 'relative',
            // overflow: 'visible',
            '& .MuiCardContent-root' : { padding: '1em 1em 0.5em 1.25em' }
        }} key={permit.unique_id}>
            {/* <div> */}
            <CardContent sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{ 
                    flexGrow: 0, flexShrink: 2, paddingRight: 0, paddingTop: '1rem',
                    // position: 'absolute', width: '20px', left: '10px', top: '-10px' 
                }}>
                {sonycType === 'placeholder' ? 
                    <Skeleton variant="rectangular" height='80px' width='80px' /> :
                    Icon && <SvgIcon inheritViewBox sx={{ width: '60px', height: 'auto' }} component={Icon} />
                    // iconTypes[sonycType] &&
                    //     <img src={iconTypes[sonycType]} alt={`${sonycType} icon`} width='60px' style={{ 
                    //         maxWidth: '100%', minWidth: '25px', filter: sonycType === 'user' ? 'invert(100%)' : null }} />
                }
            </Box>
            <Box sx={{ flexGrow: 1, padding: '1em 0.4em 0.5em' }}>
                
                {sonycType === 'placeholder' ? <Skeleton variant="text" height= {48} width={200} /> : <>
                    <Typography color='textPrimary' variant='body2'>
                        {sonycType === 'user' ? 'You reported noise at:' : 
                         sonycType === 'pin' ? 'Your pin is at:' : 
                         sonycType === 'search' ? 'Your pin is at:' : 
                         sonycType === 'DOB' ? 'DOB Building Permit:' : 
                         sonycType === '311' ? '311 Noise Complaint:' : 
                         null}
                    </Typography>
                    <Typography component={'div'} color={ !ahvPermit ? 'textPrimary' : 'secondary' } style={{fontWeight: 'bold'}}>
                        {`${address.house_num || ''} ${titleCase(address.street)}`}
                        <div style={{fontWeight:'normal'}}>
                            {`${titleCase(address.city)}, ${address.state} ${address.zip}`}
                        </div>
                    </Typography>
                </>}
                <div>
                    {permits ? (
                        <Box key={permit.unique_id}>
                            <Typography color='textSecondary' variant='body2'>
                                {sonycType === "DOB" ? formatDateRange(permit) : formatIncidentDate(permit)}
                            </Typography>
                            <Typography color='textSecondary' variant='body2'>
                                {permit.sonycType === "DOB" && formatTypeDesc(permit)}
                            </Typography>
                            {permits && permits.length > 1 && <Typography color='textSecondary' variant='body2'>
                                and {permits.length - 1} other time{permits.length>2 ? 's.' : '.'}
                            </Typography>}
                        </Box>
                    ) : formatIncidentDate(permit)}
                    {sonycType === "311" && <><br></br>{formatTypeDesc(permit)}</>}
                </div>
            </Box>
            <Box sx={{ paddingLeft: 0 }}>
                <Button onClick={() => setFocus(null)} style={{padding:'0px', minWidth: '0px', borderRadius: '100%'}}>
                    <HighlightOffIcon style={{ color: '#999999' }} />
                </Button>
            </Box>
            </CardContent>
            {/* </div> */}
            {permit != null && confirmLocation ? <CardActions>
                <Button style={{
                    // border: '1.6px solid #32659F', 
                    // color: '#32659F',
                    // textTransform: 'none', 
                    margin: '0.5em auto', 
                    borderRadius: '20px', 
                    width : '85%', 
                    // fontSize: 14, 
                    // fontWeight: 700,
                }} size='medium' variant='contained' color='primary' onClick={() => {
                    confirmLocation({ record: permit, coordinates: permit.coordinates })
                }}>Confirm location</Button>
            </CardActions> : null}
        </Card>
        </Box>
    )
}




export default SiteCard