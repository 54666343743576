import React from 'react';
import classNames from 'classnames';
import { Brush, Layer } from 'recharts';




class BetterBrush extends Brush {
    attachDragEndListener() {
      window.addEventListener('mouseup', this.handleDragEnd, true);
      window.addEventListener('touchend', this.handleDragEnd, true);
      window.addEventListener('mousemove', this.handleDrag, true); 
      window.addEventListener('touchmove', this.handleTouchMove, true); 
    }
  
    detachDragEndListener() {
      window.removeEventListener('mouseup', this.handleDragEnd, true);
      window.removeEventListener('touchend', this.handleDragEnd, true);
      window.removeEventListener('mousemove', this.handleDrag, true);
      window.addEventListener('touchmove', this.handleTouchMove, true); 
    }
  
    render() {
      const { data, className, children, x, y, width, height, alwaysShowText, style } = this.props;
      const { startX, endX, isTextActive, isSlideMoving, isTravellerMoving } = this.state;

      if (
        !data || !data.length ||
        isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height) ||
        width <= 0 || height <= 0
      ) {
        return null;
      }
  
      const isPanoramic = React.Children.count(children) === 1;
  
      return (
        <Layer className={classNames('recharts-brush', className)} style={{
            userSelect: 'none', 
            // '& > .rechartsBrushSlide': {
            //     opacity: 1,
            // },
            ...style
        }}>
          {this.renderBackground()}
          {isPanoramic && this.renderPanorama()}
          {this.renderSlide(startX, endX)}
          {this.renderTravellerLayer(startX, 'startX')}
          {this.renderTravellerLayer(endX, 'endX')}
          {(isTextActive || isSlideMoving || isTravellerMoving || alwaysShowText) && this.renderText()}
        </Layer>
      );
    }
  }
  
  export default BetterBrush;