import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from "react-use-audio-player"
import { createBox } from '@mui/system';
import { Howler } from "howler"


const Widget = styled(Box)(({ theme }) => ({
  padding: '0.3rem',
  borderRadius: 16,
  // width: 343,
  // maxWidth: '100%',
  // margin: 'auto',
  position: 'relative',
  zIndex: 2,
  // flexBasis: '30rem',
  overflow: 'hidden',
  '&:before, &:after': {
    content: '""',
    position: 'absolute', zIndex: -2,
    top: '0%', left: '0%',
    width: '100%', height: '100%',
  },
  '&.Mui-spectrogram': {
    '&:before, &:after': {
      // backgroundBlendMode: 'overlay',
      opacity: 0.5,
    }
  },
  '&:before': {
    background: (theme.palette.mode === 'dark' ?
      'linear-gradient(47deg, rgb(0 9 217) 0%, rgb(193 0 255 / 64%) 100%)'
      : 'linear-gradient(47deg, rgb(168 255 251 / 77%) 0%, rgba(33, 175, 255, 0.87) 100%)'),
      // : 'linear-gradient(47deg, rgb(0 255 242 / 77%) 0%, rgb(33 175 255 / 87%) 100%)'),
      // : 'linear-gradient(47deg, rgb(0 11 255 / 73%) 0%, rgb(33 175 255 / 87%) 100%)'),
      // : 'linear-gradient(47deg, rgb(0 9 217 / 64%) 0%, rgb(24 172 255 / 94%) 100%)'),
      // : 'linear-gradient(47deg, rgb(94 101 255 / 56%) 0%, rgb(211 75 255 / 41%) 100%)'),
  },
  '&:after': { 
    zIndex: -1, 
    backgroundColor: 'rgba(200,200,200,0.26)' 
  },
}));

const CoverImage = styled('div')({
  width: 100,
  height: 100,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: 'rgba(0,0,0,0.08)',
  '& > img': { width: '100%' },
});

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});



export default function MusicPlayer({ 
    src, title, artist, album, imageSrc, noVolume, noSeek,
    nextSong, prevSong, compact=true, loadOnPlay, howlerOptions,
    showSpec=false, sx,
}) {
  const ctlProps = compact ? {flexDirection: 'row-reverse', alignItems: 'center'} : {flexDirection: 'column'};
  return (
    <Widget sx={sx} className={showSpec ? 'Mui-spectrogram' : null}>
        <Box display='flex' alignItems='center'>
        {imageSrc && <CoverImage>
            <img
              alt={title || artist || album || 'cover-photo'}
              src={imageSrc}
            />
          </CoverImage>}
          <Box sx={{ mx: 1.5, minWidth: 0, position: 'relative' }}>
            <Typography variant="caption" color="text.secondary" fontWeight={500}>{artist}</Typography>
            <Typography noWrap><b>{title}</b></Typography>
            <Typography noWrap letterSpacing={-0.25}>{album}</Typography>
          </Box>
        </Box>

        {/* {noSeek && <SeekBar />}
        <AudioControls src={src} nextSong={nextSong} prevSong={prevSong} />
        {noVolume && <VolumeBar />} */}

        <Box display='flex' justifyContent='center' {...ctlProps}>
          {!noSeek && <SeekBar />}
          <AudioControls 
            src={src} 
            prevSong={prevSong} 
            nextSong={nextSong} 
            size={compact ? 'medium' : 'large'}
            loadOnPlay={loadOnPlay} howlerOptions={howlerOptions} />
        </Box>
        {!noVolume && <VolumeBar />}
        {showSpec && <Spectrogram style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%', zIndex: -5 }} />}
      </Widget>
  );
}


const SeekBar = () => {
  const theme = useTheme();
  const { percentComplete, duration, seek } = useAudioPosition({ highRefreshRate: true })
  const { togglePlayPause, playing } = useAudioPlayer();
  const position = percentComplete / 100 * duration
  const [ preSeek, setPreSeek ] = useState(null);

  return <Box flexGrow={1} mr='0.5rem'>
    <Slider
      aria-label="time-indicator" size="small"
      value={position} min={0} max={duration} step={0.1}
      onChange={(_, value) => { 
        if(preSeek === null) {
          setPreSeek(playing)
          togglePlayPause(false)
        }
        
        seek(value)
        
      }}
      onChangeCommitted={() => {
        if(preSeek !== null) {
          preSeek && togglePlayPause(true)
          setPreSeek(null)
        }
      }}
      sx={{
        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
        height: 4,
        marginRight: '0.8rem',
        '& .MuiSlider-thumb': {
          width: 8,
          height: 8,
          // transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',//'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
          },
          '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${
              theme.palette.mode === 'dark'
                ? 'rgb(255 255 255 / 16%)'
                : 'rgb(0 0 0 / 16%)'
            }`,
          },
          '&.Mui-active': { width: 20, height: 20 },
        },
        '& .MuiSlider-rail': {
          opacity: 0.28,
        },
        '& .MuiSlider-track': {
          transition: 'none',//'left 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 80ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      }}
    />
    <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mt: -2 }}>
      <TinyText>{formatDuration(position)}</TinyText>
      <TinyText>-{formatDuration(duration - position)}</TinyText>
    </Box>
  </Box>
}

const formatDuration = (value) => {
  const minute = Math.floor(value / 60);
  return `${minute}:${leadingZero(Math.floor(value - minute * 60))}`;
}
const leadingZero = x => x < 10 ? `0${x}` : x;


const AudioControls = ({ src, prevSong, nextSong, size='large', sx, loadOnPlay, howlerOptions }) => {
  const theme = useTheme();

  const options = {
    autoplay: false, 
    format: 'mp3',
    html5: true,
    // onload: (e) => console.log('loaded', src, e)
    ...howlerOptions,
  }
  const { togglePlayPause, ready, loading, playing, load } = useAudioPlayer(
    { ...options, src: loadOnPlay ? null : src })

  const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
  return <Box display='flex' justifyContent='center' alignItems='center' flexGrow={0} sx={{ mt: -1, ...sx }}>
      {prevSong && <IconButton aria-label="previous song" onClick={prevSong} sx={{ transform: 'scale(0.8)' }}>
        <FastRewindRounded fontSize={size} htmlColor={mainIconColor} />
      </IconButton>}
      <IconButton onClick={() => {
        loadOnPlay && !ready ? 
          load({ ...options, src, autoplay: true })
          : togglePlayPause()
      }}>
        {
        // loadOnPlay && loading ? 
        //     <DownloadingIcon fontSize={size} htmlColor={mainIconColor} /> :
        loadOnPlay && !ready ? 
            <PlayArrowOutlinedIcon fontSize={size} htmlColor={mainIconColor} /> : 
        !playing ? 
            <PlayArrowRounded fontSize={size} htmlColor={mainIconColor} /> : 
            <PauseRounded fontSize={size} htmlColor={mainIconColor} />}
      </IconButton>
      {nextSong && <IconButton aria-label="next song" onClick={nextSong} sx={{ transform: 'scale(0.8)' }}>
        <FastForwardRounded fontSize={size} htmlColor={mainIconColor} />
      </IconButton>}
    </Box>
}


const VolumeBar = () => {
  const theme = useTheme();
  const lightIconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

  const { volume, loaded } = useAudioPlayer();
  const [ volumeValue, setVolumeValue ] = useState(1);
  const handleChange = useCallback(e => {
    const v = parseFloat(e.target.value)
    volume(v)
    setVolumeValue(v)
  }, [volume])

  useEffect(() => {
    loaded && setVolumeValue(volume())
  }, [ loaded, volume ])

  return <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1, position: 'relative' }} alignItems="center" flexGrow={1}>
      <VolumeDownRounded htmlColor={lightIconColor} />
      <Slider aria-label="Volume" value={volumeValue} min={0} max={1} step={0.1}
        onChange={handleChange}
        sx={{
          height: 3,
          color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            width: 5,
            height: 15,
            borderRadius: '30%',
            backgroundColor: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
            '&:before': {
              boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible, &.Mui-active': {
              boxShadow: 'none',
            },
          },
        }}
      />
      <VolumeUpRounded htmlColor={lightIconColor} />
    </Stack>
}

export const Spectrogram = ({ fftSize=512, style, ...props }) => {
  const { playing, ready } = useAudioPlayer();
  const [ analyser, setAnalyser ] = useState();
  const [ dataArray, setDataArray ] = useState();
  const [ canvasCtx, setCanvasCtx ] = useState();
  const canvasRef = useRef();
  useEffect(() => {
    setCanvasCtx(canvasRef.current.getContext('2d'))
  }, [])
  // const [ [ canvas, canvasCtx ], setCanvas ] = useState([]);
  // const canvasRef = useCallback(node => {
  //   if(node != null) {
  //     setCanvas([ canvas, canvas.getContext("2d") ])
  //   }
  // }, []);


  useEffect(() => {
    const analyser = Howler.ctx.createAnalyser();
    analyser.fftSize = fftSize;
    setAnalyser(analyser)
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    setDataArray(dataArray)
    Howler.masterGain.connect(analyser)
    return () => { Howler.masterGain.disconnect(analyser) }
  }, [ fftSize ])

  useEffect(() => {
    if(!canvasCtx) return;
    canvasCtx.fillStyle = 'hsl(280, 100%, 10%)';
  }, [ fftSize ])

  const animate = useCallback((dt => {
    const canvas = canvasRef.current;
    const bufferLength = analyser.frequencyBinCount;
    // draw 1 time slice per column
    analyser.getByteFrequencyData(dataArray);
    
    for (var y = 0; y < bufferLength; y++) {
        const intensity = Math.min(dataArray[y]*1.5, 255);
        // const r = intensity/255;
        // canvasCtx.fillStyle = `hsl(${Math.round(((r*120) + 280)%360)})`
        canvasCtx.fillStyle = `rgb(${intensity},${intensity},${intensity})`

        const rectY = canvas.height - (bufferLength/10) * Math.log(y+1)
        const rectY2 = canvas.height - (bufferLength/10) * Math.log(y+2)
        // const rectY = canvas.height * (1 - (y) / (bufferLength))
        // const rectY2 = canvas.height * (1 - (y+1) / (bufferLength))
        // const rectY = canvas.height * (1 - Math.log(y+1) / Math.log(bufferLength))
        // const rectY2 = canvas.height * (1 - Math.log(y+2) / Math.log(bufferLength))
        canvasCtx.fillRect(canvas.width - 1, rectY, 1, rectY2 - rectY)
    }
    // shift canvas contents left by 1 pixel
    canvasCtx.drawImage(canvasCtx.canvas, -1, 0);
  }), [ analyser, canvasCtx, dataArray ]);
  
  useAnimationFrame(animate, !!analyser && ready && playing);

  return <canvas ref={canvasRef} style={style} {...props}></canvas>
}


const useAnimationFrame = (callback, enabled=true) => {
  enabled = !!enabled
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef();
  const previousTimeRef = useRef();
  
  const animate = useCallback(time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, [ callback ])
  
  useEffect(() => {
    console.log(555, enabled, callback)
    if(!enabled || !callback) return;

    requestRef.current = requestAnimationFrame(animate);
    return () => requestRef.current && cancelAnimationFrame(requestRef.current);
  }, [ callback, animate, enabled ]); // Make sure the effect runs only once
}


// const analyser = player.ctx.createAnalyser();
// const bufferLength = analyser.frequencyBinCount;
// const dataArray = new Uint8Array(bufferLength);

// player.masterGain.connect(analyser);

// // Get a canvas defined with ID "oscilloscope"
// const canvas = document.getElementById("oscilloscope");
// const ctx = canvas.getContext("2d");

// const draw = () => {
//   requestAnimationFrame(draw);
//   analyser.getByteTimeDomainData(dataArray);
//   const sq = dataArray.map(x => x^2)

//   ctx.lineWidth = 2;
//   ctx.strokeStyle = "rgb(0, 0, 0)";
//   ctx.beginPath();
// }


// const useAudioData = () => {
//   const { player, src, duration } = useAudioPlayer();
//   const analyser = useMemo(() => player.ctx.createAnalyser(), [ player, src ])
//   useEffect(() => {
//     player.masterGain.connect(analyser);
//     return () => { player.masterGain.disconnect(analyser); }
//   }, [ player, analyser ])

//   const canvasRef = useRef();
//   const ctx = useMemo(() => canvasRef.current && canvasRef.current.getContext("2d"), [ canvasRef.current ])
  

//   function draw() {
//     if(!canvasRef) return
//     requestAnimationFrame(draw);
//     analyser.getByteTimeDomainData(dataArray);
  
//     // canvasCtx.fillStyle = "rgb(200, 200, 200)";
//     // canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
  
//     ctx.lineWidth = 2;
//     ctx.strokeStyle = "rgb(0, 0, 0)";
//     ctx.beginPath();
  
//     const sliceWidth = canvas.width * 1.0 / bufferLength;
//     let x = 0;
  
//     for (var i = 0; i < bufferLength; i++) {
  
//       var v = dataArray[i] / 128.0;
//       var y = v * canvas.height / 2;
  
//       if (i === 0) {
//         ctx.moveTo(x, y);
//       } else {
//         ctx.lineTo(x, y);
//       }
  
//       x += sliceWidth;
//     }
  
//     ctx.lineTo(canvas.width, canvas.height / 2);
//     ctx.stroke();
//   }

//   useEffect(() => {
    
//     draw();
//   }, [])

//   useEffect(() => {
//     requestRef.current = requestAnimationFrame(animate);
//     return () => cancelAnimationFrame(requestRef.current);
//   }, []); // Make sure the effect runs only once
// }