import { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from 'react';

import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useKeycloak, asUrl } from 'react-kcfetch';
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from "react-use-audio-player"

// import useSWR from 'swr';

// import DBChart from 'DBChart';
import NoiseReportForm from './NoiseReport';

import { shortTimeString , titleCase, useDeploymentId, useKeycloakJSONSWR } from '../utils';
import { apiUrl, buttonIcons } from '../config'
import { useMediaQuery } from '@mui/material';
import DBChart, { MiniChart } from '../components/DBChart';
import MediaPlayer from '../components/MediaPlayer';
import { useTheme } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { WideButton } from '../components/UserInput';


const useReports = () => {
    const deployId = useDeploymentId();
    const { initialized, kcfetch } = useKeycloak();
    let { data, error } = useKeycloakJSONSWR(
      initialized && kcfetch && deployId && `${apiUrl}/reports/${deployId}`, 
      { refreshInterval: 0, revalidateOnFocus: false })
    if(error) console.error(error);
    // console.log(data);
  
    // legacy
    data = useMemo(() => {
      if(!data) return null;
      return data.map(({ startTime, endTime, duration, ...d }) => {
        startTime = Date.parse(startTime || duration?.[0]);
        endTime = Date.parse(endTime || duration?.[1]);

        return { ...d, startTime, endTime };
      }).sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    }, [data])

    return { data, error, deployId };
}


const useSpl = (start, end, interval) => {
  const deployId = useDeploymentId();
  // query and prepare the data
  let { data: spl, error: splError } = useKeycloakJSONSWR(
    start && end && asUrl(`${apiUrl}/spl/${deployId}`, { start, end, interval }), 
    { refreshInterval: 0, revalidateOnFocus: false });
  if(splError) console.error(splError);

  // let data = [];
  
  spl = useMemo(() => {
    if(!spl || !spl.length) return spl;
    if(spl[0].count) {
      return spl.map(d => ({ ...d, laeq: d.avg_laeq, time: Date.parse(d.time) }))
    }

    return spl.filter(d => d.laeq)
        .map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
        .sort((a, b) => a.time - b.time)
  }, [spl])
  return { spl, error: splError }
}


const useAudioList = (start, end) => {
  const deployId = useDeploymentId();
  let { data: audioList, error: audioListError } = useKeycloakJSONSWR(
    start && end && asUrl(`${apiUrl}/file/audio/${deployId}`, { start, end }), 
    { refreshInterval: 0, revalidateOnFocus: false });
  if(audioListError) console.error(audioListError);

  audioList = useMemo(() => {
    return audioList && audioList.map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
  }, [audioList])

  const [ selectedAudio, setSelectedAudio ] = useState();
  // console.log(audioList && audioList.map(f => f.time + 0))

  useEffect(() => { setSelectedAudio(null) }, [start, end])
  return { audioList, selectedAudio, setSelectedAudio }
}


const ReportView = ({ }) => {
    const matches = useMediaQuery('(max-width:400px)');
    const { data, deployId } = useReports();
    const [ selected, setSelected ] = useState();

    // const { reportId } = useParams();
    // const navigate = useNavigate();
    // const setSelected = sel => {
    //   navigate('/reports' + (sel?._id ? `/${sel._id}` : ''))
    // }
    // const selected = useMemo(() => data && data.find(d => d._id === reportId), [ data, reportId ])
  
    return (<>
      <Box display='flex' flexDirection='column' flexShrink={1} flexGrow={1} sx={{ minHeight: '10px' }}>
        <Collapse in={!!selected} style={{ minHeight: 'unset' }}>
          <Box display='flex' flexDirection='column' alignItems='stretch'>
          <SelectedView selected={selected} setSelected={setSelected} />
          <Button sx={{ alignSelf: 'stretch' }} onClick={() => setSelected(null)}><Puller /></Button>
          </Box>
        </Collapse>
        <Box display='flex' flexDirection='column' flexShrink={1} sx={{ padding: '2em 0', overflow: 'auto' }}>
          {data && (
            data.length ? 
              <Timeline sx={{ padding: 0, alignSelf: 'stretch' }}>
                {data && data.map((d, i) => 
                  <ReportTimelineItem key={d.startTime} 
                    previousTime={data[i-1]?.startTime} 
                    setSelected={setSelected} 
                    report={d} selected={selected}
                    briefTime={matches} />)}
              </Timeline>
              : <Box sx={{ padding: '3em 0' }}>
                  <Typography variant='h5' gutterBottom textAlign='center'>
                    No Reports submitted yet.
                  </Typography>
                </Box>
          )}
          <WideButton component={Link} to='/data?log=1'>Log Problem Noise</WideButton>
          {/* <NoiseReportForm metadata={{ deployId }} /> */}
        </Box>
        {/* {selected ? <Drawer anchor='bottom' open={true} onClose={() => setSelected(null)} variant={"persistent"} PaperProps={{ sx: { borderRadius: '40px 40px 0 0' } }}>
          <Button sx={{ alignSelf: 'stretch' }} onClick={() => setSelected(null)}><Puller /></Button>
          <SelectedView selected={selected} setSelected={setSelected} />
        </Drawer> : null} */}
      </Box>
    </>)
}

const SparkChart = ({ report }) => {
  const { startTime, endTime } = report;
  const { spl } = useSpl(startTime, endTime);
  return <MiniChart data={spl} />
}


const SelectedView = ({ selected }) => {
  const theme = useTheme();

  const { startTime, endTime, noisetype, activity, comment, backgroundLevel, peakLevel } = selected || {};
  const { spl } = useSpl(startTime, endTime);
  const { audioList, selectedAudio, setSelectedAudio } = useAudioList(startTime, endTime);
  const { id: audioId, avg_laeq, max_laeq, time } = selectedAudio || {};

  return (<>
  <Stack direction='row'>
    <Box px='1em' py='1.3em'>
      {noisetype && activity && <Typography variant="h6">
        "{noisetype}" noise during "{activity}"
      </Typography>}
      {<Typography variant="subtitle1">
        Background Level: {backgroundLevel || '--'} dBA, Peak Level: {peakLevel || '--'} dBA
      </Typography>}
      {/* {startTime && endTime && <Typography variant='h5'>
        {`${shortTimeString(startTime)} - ${shortTimeString(endTime)} on ${new Date(endTime).toLocaleDateString()}`}
      </Typography>} */}
      {comment && <Typography variant='subtitle1'><pre>{comment}</pre></Typography>}
    </Box>
    {/* {selectedAudio && <AudioPlayerProvider key={selectedAudio.id}>
        <MediaPlayer
          sx={{ alignSelf: 'stretch', margin: '1em' }}
          title={<>
            {new Date(time).toLocaleTimeString()} {'  '}
            <small>{new Date(time).toLocaleDateString()
        }</small></>}
        artist={`Average: ${selectedAudio.avg_laeq ? Math.round(selectedAudio.avg_laeq) : '-'} dB(A), Peak: ${selectedAudio.max_laeq ? Math.round(selectedAudio.max_laeq) : '-'} dB(A)`}
        loadOnPlay noVolume src={`${apiUrl}/file/id/${audioId}`} />
      </AudioPlayerProvider>} */}
  </Stack>
    <DBChart data={spl} height='20rem' brush audioList={audioList}>
      {/* {audioList && audioList.map(f => 
        // <Tooltip key={f.id} title=''>
          <ReferenceArea 
            key={f.id} x1={f.time+0} x2={f.time + 10 * 1000} strokeWidth={0.8}
            fill={audioId !== f.id ? 'transparent' : theme.palette.primary.light} //theme.palette.background.default
            stroke={theme.palette.text.primary} // audioId === f.id ? theme.palette.text.primary : null
            onClick={() => setSelectedAudio(audioId === f.id ? null : f)} />
        // </Tooltip>
      )} */}
    </DBChart>
    <Typography>
    Click on a rectangle to listen to an audio clip for that region.
  </Typography>
    {/* <Stack>
    
    </Stack> */}
  </>)
}

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.primary.main,//theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  // position: 'absolute',
  // top: 8,
  // left: 'calc(50% - 15px)',
  // padding: '0.1em 0.2em',
}));

  
const paddingLeft = '1rem'

const TlItem = ({ sx, ...props }) => {
  return <TimelineItem sx={{
    '&.MuiTimelineItem-missingOppositeContent:before, & .MuiTimelineOppositeContent-root': { 
      paddingLeft,
      // flex: 0.2,
    },
    // '& .MuiTimelineOppositeContent-root': { paddingLeft },
    '& .MuiTimelineContent-root': { paddingLeft },
    ...sx,
  }} {...props} />
}

const ReportTimelineItem = ({ 
    report={},
    selected,
    setSelected,
    timeGapThreshold=2,
    briefTime,
    previousTime,
   }) => {
     let {
      noisetype, activity, startTime, endTime, duration,
      record: { address={} }={}, comment, _id,
      backgroundLevel, peakLevel, 
    } = report;
    const theme = useTheme();
    const isSelected = selected && selected._id === _id
    const smallWindow = useMediaQuery('(max-width:300px)');
    const days = (startTime - previousTime) / 1000 / (60*60*24)
    // console.log(days, startTime, previousTime)
    return (<>
      {days >= timeGapThreshold && (
      <TlItem>
        <TimelineSeparator>
          <TimelineConnector />
          <Tooltip title={`${Math.round(days)} day${Math.round(days)>1?"s":""} later`} placement='right'>
          <TimelineDot color='primary' sx={{ padding: `${Math.round(Math.min(days, 8))}px` }}></TimelineDot>
          </Tooltip>
          <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ }}></TimelineContent>
    </TlItem>)}
    <TlItem  onClick={setSelected && (() => setSelected(isSelected ? null : report))} sx={{
      borderRadius: '10px',
      transition: 'background 0.2s ease-in-out',
      // backgroundImage: isSelected ? theme.palette.background.gradient : null,
      backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.4) : null,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },
    }}>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary">
        {/* {briefTime ? (<> */}
          {new Date(startTime).toLocaleDateString([], { month: 'short', day: 'numeric' })}
          <Typography>
          <b style={{ whiteSpace: 'nowrap'}}>{(shortTimeString(startTime) || '').toLowerCase()}</b>
          </Typography>
          <Typography>
          <b style={{ whiteSpace: 'nowrap'}}>to {(shortTimeString(endTime) || '').toLowerCase()}</b>
          </Typography>
        {/* </>) : (<>
          {new Date(startTime).toLocaleDateString([], { month: 'short', day: 'numeric' })}
          <Typography>
          <b style={{ whiteSpace: 'nowrap'}}>{(shortTimeString(startTime) || '').toLowerCase()}</b>
          {' - '}
          <b style={{ whiteSpace: 'nowrap'}}>{(shortTimeString(endTime) || '').toLowerCase()}</b>
          </Typography>
          {address.house_num} {titleCase(address.street)}
        </>)} */}
        
      </TimelineOppositeContent>
        <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color='primary' variant={isSelected ? 'selected' : 'outlined'} sx={{ padding: '5px' }}>
          {/* {smallWindow ? (<Tooltip title={
            `${noisetype} | ${activity}${comment?` | ${comment}` : ''}`
          }> */}
            <IconButton onClick={setSelected && (() => setSelected(isSelected ? null : report))}>
              {buttonIcons[noisetype]}
            </IconButton>
          {/* </Tooltip>) : buttonIcons[noisetype]} */}
        </TimelineDot>
        <TimelineConnector sx={{ bgColor: 'secondary' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2, padding: '1.5em 1em' }}>
      {/* {!smallWindow && <> */}
        <Typography variant="h6" component="span">
          "{noisetype}" noise during "{activity}"
        </Typography>
        {<Typography variant="subtitle1">
          Background Level: {backgroundLevel || '--'} dBA, Peak Level: {peakLevel || '--'} dBA
        </Typography>}
        {/* <SparkChart report={report} /> */}
        {comment && <Typography variant='subtitle1'><pre>{comment}</pre></Typography>}
        <div style={{ display: 'none' }}>{_id}</div>
        {/* </>} */}
      </TimelineContent>
    </TlItem>
    </>)
   }


   export default ReportView;