import React, { useState, useMemo, createContext, useContext, useCallback } from 'react';
import { createTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const bgDark = '#000320'//'#080b2c' //'#0c0f32'


export const DarkModeContext = createContext({ darkMode: null, setDarkMode: () => {} });
export const useColorMode = () => useContext(DarkModeContext);

// export const DarkModeProvider = ({ children }) => {
//   // system level preference
//   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
//   // get previous preference from local storage
//   const [ darkMode, setDarkMode_ ] = useState(() => {
//     const localDarkMode = localStorage.getItem("darkMode");
//     return localDarkMode !== null ? localDarkMode === 'y' : prefersDarkMode;
//   });

//   const setDarkMode = useCallback(enabled => {
//     // only set local storage if it's different from system preference
//     if(enabled !== prefersDarkMode) { localStorage.setItem("darkMode", enabled) } 
//     else { localStorage.removeItem("darkMode") }
//     // update dark mode if it changed
//     if(enabled !== darkMode) { setDarkMode_(enabled) }
//   }, [ prefersDarkMode, darkMode ])
  
//   return <ColorModeContext.Provider value={{ darkMode, setDarkMode }}>
//     {children}
//   </ColorModeContext.Provider>
// }

const success = '#44f059';

const gradients = {
  'warmBackground': 'linear-gradient(47deg, rgb(162 166 255 / 52%) 0%, rgb(160 0 212 / 19%) 100%)',
}

const getTheme = (darkMode) => {
  const mode = darkMode ? 'dark' : 'light'
  let theme = createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          primary: {
            main: '#32659F',
            // light: primaryLight,
            light: '#aed4ff',//'#6792d0',
            dark: '#003b70',
            lighter: '#f0f8ff',
          },
          secondary: {
            main: '#7777FF',
            light: '#ada6ff',
            dark: '#3c4bcb',
          },
          text: {
            // primary: '#fff',
            // secondary: '#fff',
            special: '#e83e8c',
          },
          background: {
            gradient: 'linear-gradient(45deg, #9a9a9a00, #33659f17)',
            success,
          },
          gradients,
        }
      : {
          primary: {
            main: '#97aaff', //'#32659F', //'#7ab8ff',
            light: '#cbdbff',
            dark: '#647bcb',
            lighter: '#aed4ff',
          },
          secondary: {
            main: '#92e5ff',//'#2dc2ff',
            light: '#c6ffff',
            dark: '#5eb3cc',
            lighter: '#aed4ff',
          },
          background: {
            default: bgDark,
            paper: bgDark,
            dark: '#010320',
            light: '#1d267e',
            gradient: 'linear-gradient(45deg, #00000000, #00000075)', //'linear-gradient(45deg, #000000d9, #00000029)',
            success,
          },
          gradients,
        }),
  
      // background: {
      //   main: mpde == 'dark' ? #000926',
      // }
    },
    typography: {
      h1: { fontSize: 50 },
      h2: { fontSize: 40 },
      h3: { fontSize: 30 },
      h4: { fontSize: 24 },
      h5: { fontSize: 18 },
      h6: { fontSize: 14 },
      subtitle1: { 
        fontStyle: 'italic',
        fontSize: 14,
        // letterSpacing: '0.07938em',
      },
      subtitle2: { 
        fontStyle: 'italic',
        fontSize: 12,
        // letterSpacing: '0.07938em',
      },
    },
    components: {      
    },
  })

  return createTheme(theme, {
    typography: {
      subtitle1: { color: theme.palette.secondary.main },
      special: { 
        color: theme.palette.text.special,
        fontFamily: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
        fontSize: '87.5%',
        wordBreak: 'break-word',
        backgroundColor: theme.palette.background.dark,
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'selected' },
            style: {
              background: 'linear-gradient(15deg, #7543d761 30%, #1717da3b 90%)', //'linear-gradient(0deg, #CCCDFF 30%, #CCCCFF 90%)',
              // borderColor: '#7777FF',
              borderColor: theme.palette.mode !== 'dark' ? '#0c0cff' : '#BBBBFF',
              // borderWidth: '0.2em',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderRadius: '3px',
              // padding: '3px 9px',
              // fontSize: '0.8125rem',
            },
          },
          {
            props: { variant: 'selected-text' },
            style: {
              background: 'linear-gradient(15deg, #7543d761 30%, #1717da3b 90%)', //'linear-gradient(0deg, #CCCDFF 30%, #CCCCFF 90%)',
              borderRadius: '3px',
              // fontSize: '0.8125rem',
            },
          },
          {
            props: { variant: 'wide-outlined' },
            style: {
              borderRadius: '20px' ,
              border: `1.5px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              width : '90%',
              margin: "0.5em auto",
              fontSize: 14, 
              fontWeight: 600,
              textTransform: 'none',
            },
          },
          {
            props: { variant: 'wide-contained' },
            style: {
              margin: '1em auto 1.5em',
              width: '90%',
              borderRadius: '20px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.background.default,
            },
          },
        ],
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: theme.palette.background.dark,
          }
        }
      },

      MuiTimelineDot: {
        variants: [
          {
            props: { variant: 'selected' },
            style: {
              background: 'linear-gradient(15deg, #7543d761 30%, #1717da3b 90%)', //'linear-gradient(0deg, #CCCDFF 30%, #CCCCFF 90%)',
              borderColor: '#7777FF',
              borderWidth: '0.2em',
              borderStyle: 'solid',
            },
          },
        ]
      }
    }
  })
};

export default getTheme;