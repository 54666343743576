import { useState } from 'react';
import { useParams } from 'react-router';
import { AudioPlayerProvider } from "react-use-audio-player"
import Box from '@mui/material/Box';
import MediaPlayer from '../components/MediaPlayer';
import { useKeycloakJSONSWR } from '../utils';
import { apiUrl } from '../config';

export const SonycAudioFile = ({ id, data, ...props }) => {
    let { data: fileData, error: fileError } = useKeycloakJSONSWR(
        id && !data && `${apiUrl}/file/id/${id}/meta`, 
        { refreshInterval: 0, revalidateOnFocus: false })
    if(fileError) console.error(fileError);
    data = data || fileData;
    const { time, avg_laeq, max_laeq } = data || {};
    
    return (
        <Box display='flex' direction='column' justifyContent='center' alignItems='center'>
            <AudioPlayerProvider>
                <MediaPlayer 
                    src={`${apiUrl}/file/id/${id}`} 
                    title={time && <>
                        {new Date(time).toLocaleTimeString()} {'  '}
                        <small>{new Date(time).toLocaleDateString()}</small></>}
                    artist={avg_laeq && `Average: ${avg_laeq ? Math.round(avg_laeq) : '-'} dB(A), Peak: ${max_laeq ? Math.round(max_laeq) : '-'} dB(A)`}
                    noVolume howlerOptions={{ }} {...props} />
            </AudioPlayerProvider>
    </Box>)
}


const AudioView = ({ ...props }) => {
    const { id } = useParams();
    return <SonycAudioFile id={id} {...props} />
}

export default AudioView
