import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useColorMode } from '../theme';
import { SonycSwitch } from './NoiseReport';
import MusicPlayer from '../components/MediaPlayer';
import { AudioPlayerProvider } from 'react-use-audio-player';



const DesignView = () => {
    const theme = useTheme();
    const { darkMode, setDarkMode } = useColorMode();
  
    return (<Box sx={{ width: '100%', maxWidth: 500, padding: '2em' }}>
      <IconButton color="inherit" onClick={() => setDarkMode(!darkMode)}>
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      <Typography variant="h1" gutterBottom>h1. Heading</Typography>
      <Typography variant="h2" gutterBottom>h2. Heading</Typography>
      <Typography variant="h3" gutterBottom>h3. Heading</Typography>
      <Typography variant="h4" gutterBottom>h4. Heading</Typography>
      <Typography variant="h5" gutterBottom>h5. Heading</Typography>
      <Typography variant="h6" gutterBottom>h6. Heading</Typography>
      <Typography variant="subtitle1" gutterBottom>
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur
      </Typography>
      <Typography variant="body1" gutterBottom>
        body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
      </Typography>
      <Typography variant="body2" gutterBottom>
        body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
      </Typography>
      <Typography variant="button" display="block" gutterBottom>
        button text
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        caption text
      </Typography>
      <Typography variant="overline" display="block" gutterBottom>
        overline text
      </Typography>
      <Stack flexDirection='row'>
        <Swatch color={theme.palette.primary.main} label={'primary.main'} invertText />
        <Swatch color={theme.palette.primary.dark} label={'primary.dark'} invertText />
        <Swatch color={theme.palette.primary.light} label={'primary.light'} invertText />
        <Swatch color={theme.palette.primary.lighter} label={'primary.lighter'} invertText />
      </Stack>
      <Stack flexDirection='row'>
        <Swatch color={theme.palette.secondary.main} label={'secondary.main'} invertText />
        <Swatch color={theme.palette.secondary.dark} label={'secondary.dark'} invertText />
        <Swatch color={theme.palette.secondary.light} label={'secondary.light'} invertText />
        <Swatch color={theme.palette.secondary.lighter} label={'secondary.lighter'} invertText />
      </Stack>
      <Stack flexDirection='row'>
        <Swatch color={theme.palette.text.primary} label={'text.primary'} invertText />
        <Swatch color={theme.palette.text.secondary} label={'text.secondary'} invertText />
      </Stack>
      <Stack flexDirection='row'>
        <Swatch color={theme.palette.background.default} label={'background.default'} />
        <Swatch color={theme.palette.background.paper} label={'background.paper'} />
        <Swatch color={theme.palette.background.dark} label={'background.dark'} />
      </Stack>
      <Stack flexDirection='row' sx={{ margin: 2, '& button': {marginRight: '1em'} }}>
        <Button>default</Button>
        <Button variant='outlined'>outlined</Button>
        <Button variant='contained'>contained</Button>
        <Button variant='selected'>selected</Button>
      </Stack>

      <Stack flexDirection='row' sx={{ margin: 2, '& > *': {marginRight: '1em'} }}>
        <SonycSwitch />
      </Stack>

      <Stack sx={{ margin: 2, '& > *': {marginBottom: '1em'} }}>
        <AudioPlayerProvider>
            <MusicPlayer title='default' src='/sonyc_sample.wav' />
            <MusicPlayer title='no volume' noVolume src='/sonyc_sample.wav' />
            <Stack flexDirection='row' sx={{ '& > .MuiBox-root': {marginRight: '1em'} }}>
                <MusicPlayer title='no seek' noVolume noSeek src='/sonyc_sample.wav' sx={{ alignSelf: 'center', justifyContent: 'center' }} />
                <MusicPlayer title='spec' noVolume noSeek showSpec src='/sonyc_sample.wav' sx={{ alignSelf: 'center', justifyContent: 'center' }} />
            </Stack>
            <MusicPlayer title='spectrogram' noVolume showSpec src='/sonyc_sample.wav' />
        </AudioPlayerProvider>
      </Stack>

      
  </Box>)
  }
  
  const Swatch = ({ color, label, invertText }) => {
    const theme = useTheme();
    return color ? <Stack sx={{ 
          borderRadius: '4px', 
          backgroundColor: color, 
          boxShadow: 3, 
          width: '130px', height: '130px', 
          margin: '0.5em', textAlign: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          color: invertText ? theme.palette.background.default : theme.palette.text.primary,
      }}>
      <small>{label}</small>
      <br/>
      {color}
    </Stack> : null
  }

  export default DesignView;