import { useEffect, useRef, useState, useMemo, createContext, useContext } from 'react';
import useSWR from 'swr';
import { useKeycloak, asUrl } from 'react-kcfetch';
import { useParams } from "react-router-dom";



export const shortTimeString = (d, o={}) => d && (!isNaN(new Date(d)) ? new Date(d).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', ...o}) : d)


export const titleCase = (text) => {
    return text ? text.split(' ').map(
      w => w.charAt(0).toUpperCase() + w.substr(1, w.length).toLowerCase()
    ).join(' ') : '';
}

export const pascalCase = (w) => {
  return w ? w.charAt(0).toUpperCase() + w.substr(1, w.length) : '';
}


//
export const dateMath = (str) => {
  return Array.from(str.matchAll(/([-+ ]*)(\d+)(\w+)/g), m => 
  (m[1].trim().includes('-') ? -1 : 1) * parseFloat(m[2].trim()) * scales[m[3].trim().toLowerCase()] * 1000
).reduce((s, x) => s+x, 0)
}
// dateMath('1w+1d-3h + 20m')
const scales = {s: 1, m: 60, h: 60*60, d: 60*60*24, w: 60*60*24*7}


export const timeoutPromise = (interval, x, success=true) => new Promise((r, rj) => { setTimeout(() => (success ? r : rj)(x), interval) })


export const useKeycloakJSONSWR = (url, { params, requireAuth, ...options }={}) => {
  const { initialized, kcfetch } = useKeycloak();
  return useSWR(
    initialized && url && asUrl(url, params), 
    url => 
    kcfetch(url)
      .then(r => r.json())
      .then(r => { if(r.error) throw r; return r; })
      .then(r => {
        if(r.status === 401) {

        }
        return r;
      })
      .catch(e => console.error(e)), 
    options)
}


const LS = window.localStorage;
export const useLocalStore = (key, { defaultValue, interval, clearValue }={}) => {
  let [ value, setValue ] = useState(() => LS_tryparse(LS.getItem(key)));

  // const clearValueRef = useRef();
  // clearValueRef.current = clearValue
  
  // detect changes to the local store
  useEffect(() => {
    if(!interval) return;
    const s = value !== null ? JSON.stringify(value) : null;
    const id = setInterval(() => {
      const s2 = LS.getItem(key);
      (s !== s2) && setValue(LS_tryparse(s2));
    })
    return () => { clearInterval(id) }
  }, [ key, value, interval ]);

  // handle default value
  value = value != null ? value : defaultValue;

  // keep localStorage synced
  useEffect(() => {
    if(value !== null) {
      if(clearValue !== null && clearValue === value) {
        LS.removeItem(key);
      } else {
        LS.setItem(key, JSON.stringify(value));
      }
    }
  }, [ key, value, clearValue ]);

  return [ value, setValue ]
}

const LS_tryparse = x => {
  if(x !== null) {
    try { return JSON.parse(x) } 
    catch (e) { console.error(e) }
  }
}

const createLocalStoreContext = ({ key, ...args }={}) => {
  const Context = createContext();
  const useStore = () => useContext(Context)

  const Provider = ({ children, extraValue, ...props }) => {
    const [ value, setValue ] = useLocalStore(key, { ...args, ...props });
    return <Context.Provider value={[ value, setValue, ...extraValue ]}>
        {children}
    </Context.Provider>
  }

  return [ useStore, Provider ];
}

const [ useDeployId, BaseDeployIdProvider ] = createLocalStoreContext({ key: 'sonyc:deploy-id' });
export { useDeployId };

export const DeployIdProvider = ({ ...props }) => {
  const { keycloak, initialized } = useKeycloak();
  const token = initialized && keycloak && keycloak.token;
  const myDeployId = token && keycloak.tokenParsed.deployment_id[0]
  return <BaseDeployIdProvider defaultValue={myDeployId} clearValue={myDeployId} extraValue={[myDeployId]} {...props} />
}




export const hasRole = (keycloak, role) => {
  return keycloak?.tokenParsed?.realm_access?.roles?.includes(role)
  // return (((keycloak || {}).tokenParsed || {}).realm_access.roles || []).includes(role)
}

export const useDeploymentId = () => {
  // const { keycloak, initialized } = useKeycloak();
  // const token = initialized && keycloak && keycloak.token;
  // const myDeployId = token && keycloak.tokenParsed.deployment_id[0]

  // // let { deployId } = useParams();
  // // const [ deployId, setDeployId ] = useLocalStore('sonyc:deploy-id', { clearValue: myDeployId })
  const [ deployId, setDeployId ] = useDeployId();
  return deployId// || myDeployId;
}


export const useInterval = (callback, delay) => {
  const cb = useRef();
  useEffect(() => { cb.current = callback; }, [callback]);
  useEffect(() => {
      if (delay !== null) {
          let id = setInterval(() => cb.current(), delay);
          return () => clearInterval(id);
      }
  }, [delay]);
}