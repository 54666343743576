import React, { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect } from 'react';
import { useTheme } from "@mui/material/styles";

import { useKeycloak } from 'react-kcfetch';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import Slider from '@mui/material/Slider';

import { AudioPlayerProvider } from "react-use-audio-player"
import MediaPlayer from '../components/MediaPlayer';

import { 
    LineChart, XAxis, YAxis, Line, ResponsiveContainer, ReferenceArea, ReferenceLine, ReferenceDot,
    CartesianGrid, Tooltip as ChartTooltip, Legend, Brush } from 'recharts';
  
import { shortTimeString, useDeploymentId } from '../utils';
import BetterBrush from './BetterBrush';
import { ErrorReportButton } from './utils';

// import debounce from 'lodash.debounce';
import useResizeObserver from '@react-hook/resize-observer'

import { apiUrl } from '../config';




const useSize = (target, { wait=100, maxWait=100 }={}) => {
  const [ size, setSize ] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, useMemo(() => (entry) => {
    const { width, height } = entry.contentRect
    setSize({ width, height })
  }, []))
  // debounce(, wait, { maxWait })

  // useEffect(() => {console.log(555, size)}, [ size ])
  return size
}



const roundDownModulo = (x, i=10) => x - (x%i)
const roundUpModulo = (x, i=10) => x + (i - (x%i))


const useDataGaps = (data, { breakGap, xkey, ykey }) => {
  data = useMemo(() => {
    if(!data) return null;
    if(!data.length) return [];

    const breakGapMs = breakGap * (data[data.length-1][xkey] - data[0][xkey]) / data.length;

    const nullData = []
    var lastTime = null;
    for(let d of data || []) {
      if(lastTime && d[xkey] - lastTime > breakGapMs) {
        nullData.push({ [xkey]: new Date(lastTime) + breakGapMs-1000, [ykey]: null })
      }
      nullData.push(d);
      lastTime = new Date(d[xkey])
    }
    return nullData.length ? nullData : null;
  }, [data, breakGap, xkey, ykey]);
  return data
}
    

const DBChart = ({ data, xkey='time', ykey='laeq', maxkey='max_laeq', minkey='min_laeq', audioList, xMaxNow, height, noAxes, breakGap=10, backgroundLevel, brush, children, audioAbove, disableAudioPlayer }) => {
    const { keycloak } = useKeycloak();
    const deployId = useDeploymentId();
    const theme = useTheme();
  
    
    data = useDataGaps(data, { breakGap, xkey, ykey })
    const hasMax = data && data.length && maxkey in data[0]

    // const [ blah, setBlah ] = useState()

    const boxRef = useRef();
    const { width: w, height: h } = useSize(boxRef);

    const minSpl = useMemo(() => data && Math.min(...data.map(d=> d[minkey] || d[ykey])), [data, minkey, ykey])
    const [ selectedAudio, setSelectedAudio ] = useState();

    useEffect(() => {
      if(selectedAudio && (!audioList || !audioList.some(f => f.id === selectedAudio.id))) {
        setSelectedAudio(null);
      }
    }, [selectedAudio, audioList])

    // // const defaultDomain = ['dataMin', xMaxNow ? Date.now()+0 : 'dataMax'];
    // const bounds = useMemo(() => (data && data.length ? ([
    //   data[0].time, xMaxNow ? Date.now()+0 : data[data.length-1].time
    // ]) : [Date.now()+0, Date.now()+0]), [ data, xMaxNow ]);
    // let [ brushPos, setBrushPos ] = useState();
    // brushPos = brushPos || bounds;

    // useEffect(() => { console.log('new bounds', bounds)}, [bounds])
    // useEffect(() => { console.log('new brush', brushPos)}, [brushPos])
  
    // maxWidth: '100%', minHeight: '50px', maxHeight: '70vh', height: '100vh', 
    // <ResponsiveContainer height="100%"></ResponsiveContainer>

    // const [ iii, setiii ] = useState();
    // useEffect(() => {
    //   const id = setTimeout(() => { setiii(2); console.log('next') }, 2000)
    //   return () => { id && clearTimeout(id) }
    // }, [])

    // console.log([`dataMin + ${brushPos[0] - bounds[0]}`, `dataMin - ${bounds[1] - brushPos[1]}`])

    const audioPlayer = <Stack>
    {!disableAudioPlayer && selectedAudio && <AudioPlayerProvider key={selectedAudio.id}>
        <MediaPlayer
          sx={{ alignSelf: 'stretch', margin: '1em' }}
          title={<>
            {new Date(selectedAudio.time).toLocaleTimeString()} {'  '}
            <small>{new Date(selectedAudio.time).toLocaleDateString()
        }</small></>}
        artist={`Average: ${selectedAudio.avg_laeq ? Math.round(selectedAudio.avg_laeq) : '-'} dB(A), Peak: ${selectedAudio.max_laeq ? Math.round(selectedAudio.max_laeq) : '-'} dB(A)`}
        noVolume src={`${apiUrl}/file/id/${selectedAudio.id}`} />
      </AudioPlayerProvider>}
    </Stack>;

    // fix from here: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
    return (
      <>
      {audioAbove && audioPlayer}
      <Typography style={{marginLeft: '1.5em', fontSize: 16, fontWeight: 600}}>
        <TimeSpan data={data} />
      </Typography>
      <Box ref={boxRef} display='flex' flexDirection='column' flexGrow={10} flexShrink={1} sx={{ padding: '5px', minHeight: '150px', maxHeight: '100vh', height, minWidth: 0 }}>
        {data ? (!data.length ? (
          <Box height='100%' 
              display='flex' flexDirection='column' justifyContent='center' alignItems='center'
              sx={{ margin: '0 1em', flexGrow: 1, transform: 'scaleY(1)', border: '1px solid #6662' }}>
            <Typography variant='h5' gutterBottom align='center'>
              Hmmm, looks like your sensor isn't uploading data.
            </Typography>
            <Typography variant='h4' gutterBottom align='center'>
              First thing to try is <b>unplugging your sensor and plugging it back in.</b>
            </Typography>
            <Typography variant='h5' gutterBottom align='center'>
              If that doesn't work:
            </Typography>
            <Stack spacing={2} direction="column">
              {/* <Button
                variant="contained" rel="noopener noreferrer" target='_blank'
                href={`mailto:bsteers@nyu.edu?subject=No Data for ${deployId}&body=${(
                  `Submitted by: ${keycloak?.tokenParsed?.preferred_username}%0D%0D`
                )}`}>
                Give us a shout
              </Button> */}
              <ErrorReportButton subject='No Data - Sensor is down' label='Give us a shout' />
            </Stack>
          </Box>
        ) : (<>
        <LineChart width={w} height={h} data={data} dataKey={d=>d.time} margin={{ top: 5, left: -30, right: 5, bottom: 5 }}>
          {/* <CartesianGrid horizontal={false} vertical={false} fill={theme.palette.primary.light} /> */}
          <ReferenceLine y={80} 
            stroke={theme.palette.secondary.main} 
            strokeWidth={2} strokeDasharray="6 9" />
          {backgroundLevel && <ReferenceLine y={backgroundLevel} stroke="#ddd" strokeWidth={2} />}
          
          {!noAxes && <>
            <XAxis 
                dataKey={xkey} 
                tick={{ fontSize: 11, fill: theme.palette.text.primary, fontWeight: 700 }} 
                axisLine={{ strokeWidth: 2 }}
                dy={10} interval='preserveStartEnd' 
                type='number' domain={['dataMin', xMaxNow ? Date.now()+0 : 'dataMax']}  tickCount={10}
                tickFormatter={(d) => shortTimeString(new Date(d))} color='black' />
            <YAxis 
                dataKey={ykey} 
                axisLine={false} 
                tick={{ fontSize: 15, fill: theme.palette.text.primary, fontWeight: 600 }} 
                type='number' tickSize={2} allowDataOverFlow={true}
                domain={[m => Math.min(roundDownModulo(m-5), 30), m => Math.max(roundUpModulo(m+5), 100)]} 
                interval='preserveStartEnd' tickFormatter={Math.round}  />
          </>}

          {audioList && audioList.map(f => 
            // <Tooltip key={f.id} title=''>
              <ReferenceArea 
                key={f.id} x1={f.time+0} x2={f.time + 10 * 1000}   y2={minSpl -2}
                fill={!(selectedAudio && selectedAudio.id === f.id) ? theme.palette.background.default : theme.palette.primary.light}
                stroke={theme.palette.text.primary} // selectedAudio && selectedAudio.id === f.id ? theme.palette.text.primary : null
                onClick={() => setSelectedAudio(selectedAudio && selectedAudio.id === f.id ? null : f)} />
              // <ReferenceDot 
              //   key={f.id} x={f.time + 5 * 1000} y={minSpl - 2} r={9}
              //   fill={!(selectedAudio && selectedAudio.id === f.id) ? theme.palette.background.default : theme.palette.primary.light}
              //   stroke={theme.palette.text.primary} // selectedAudio && selectedAudio.id === f.id ? theme.palette.text.primary : null
              //   onClick={() => setSelectedAudio(selectedAudio && selectedAudio.id === f.id ? null : f)} />
            // </Tooltip>
          )}


          <Line name={hasMax ? 'Average' : 'Sound Level'}
            type="monotone" dataKey={ykey} dot={false} connectNulls={false}
            stroke={theme.palette.primary.main} strokeWidth={1} 
            animationEasing='ease-in-out' animationDuration={1000} />
          {hasMax && <Line name='Max'
            type="monotone" dataKey={maxkey} dot={false} connectNulls={false}
            stroke={theme.palette.secondary.main} strokeWidth={1}  strokeDasharray="1 4"
            animationEasing='ease-in-out' animationDuration={1000} />}
  
          {hasMax && <Line name='Min'
            type="monotone" dataKey={minkey} dot={false} connectNulls={false}
            stroke={'#aaa6'} strokeWidth={1}
            animationEasing='ease-in-out' animationDuration={1000} />}
          <ChartTooltip 
            formatter={(value, name, props) => (
              // name.includes('laeq') ? Math.round(value) + ' dB(A)' : value
              Math.round(value) + ' dB(A)'
            )} 
            // offset={{ x: 0, y: -100 }} 
            allowEscapeViewBox={{ y: true }}
            labelFormatter={d => new Date(d).toLocaleTimeString()}
            contentStyle={{ backgroundColor: theme.palette.background.default }} />
          <Legend iconType='plainline' iconSize={11} />
          {brush && 
            <BetterBrush height={20} travellerWidth={14} 
              fill='none' stroke={theme.palette.text.primary} 
              dataKey='time' tickFormatter={d => ''} />}
          {children}
        </LineChart>
        </>)) : 
        <Skeleton height='100%' sx={{ margin: '0 1em', flexGrow: 1, transform: 'scaleY(1)' }} />}
      </Box>
        {!audioAbove && audioPlayer}
      </>)
  }

export const MiniChart = ({ height=60, data, xkey='time', ykey='laeq', avgkey='avg_laeq', maxkey='max_laeq', minkey='min_laeq' }) => {
  const theme = useTheme();
  const hasMax = data?.length && maxkey in data[0]
  ykey = hasMax ? avgkey : ykey
  return (
    <Box height={height}>
      <LineChart width={200} height={height} data={data} dataKey={xkey} margin={{  }}>
      {/* <XAxis dataKey="name" />
          <YAxis /> */}
        <ReferenceLine y={80} 
            stroke={theme.palette.secondary.main} 
            strokeWidth={0.8} strokeDasharray="6 9" />
        <Line name={hasMax ? 'Average' : 'Sound Level'}
            type="monotone" dataKey={ykey} dot={false} connectNulls={false}
            stroke={theme.palette.primary.main} strokeWidth={1} 
            animationEasing='ease-in-out' animationDuration={1000} />
        {hasMax && <Line name='Max'
            type="monotone" dataKey={maxkey} dot={false} connectNulls={false}
            stroke={theme.palette.secondary.main} strokeWidth={1}  strokeDasharray="1 4"
            animationEasing='ease-in-out' animationDuration={1000} />}
  
        {hasMax && <Line name='Min'
            type="monotone" dataKey={minkey} dot={false} connectNulls={false}
            stroke={'#aaa6'} strokeWidth={1}
            animationEasing='ease-in-out' animationDuration={1000} />}
      </LineChart>
    </Box>
  )
}


const TimeSpan = ({ data }) => {
  if(!data) return <Skeleton width='300px' height='50px' />;
  if(!data.length) return null;

  const min = Math.min(...data.map(d => d.time));
  const max = Math.max(...data.map(d => d.time));

  let minDate = new Date(min).toLocaleDateString([], { month: 'short', day: 'numeric' });
  let maxDate = new Date(max).toLocaleDateString([], { month: 'short', day: 'numeric' });
  const todayDate = new Date(Date.now()).toLocaleDateString([], { month: 'short', day: 'numeric' });

  minDate = minDate === todayDate ? 'Today' : minDate;
  maxDate = maxDate === todayDate ? 'Today' : maxDate;

  return <>
    {minDate === maxDate ? 
      `${maxDate} ${shortTimeString(min)} - ${shortTimeString(max)}`
      : `${minDate} ${shortTimeString(min)} - ${maxDate} ${shortTimeString(max)}`}
  </>
}


  export default DBChart;