import React, { useState, useMemo, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';
import KeycloakProvider from 'react-kcfetch';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
// import { blue } from '@mui/material/colors';
import getTheme, { DarkModeContext } from './theme';
import { keycloakOptions } from './config';



const useKeySequence = (seq, success, { delay=300 }={}) => {
  useEffect(() => {
    let count = 0;
    let id;
    const handleKey = e => {
      id && clearTimeout(id);
      id = null;
      if (e.shiftKey && e.key === seq[count]) {
        count++;
        id = setTimeout(() => { count = 0; }, delay)
        if(count === seq.length) {
          success();
        }
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => { window.removeEventListener('keydown', handleKey) };
  }, [seq, success, delay]);
}


const Root = () => {
  // get the desired dark/light setting
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const [ darkMode, setDarkMode_ ] = useState(() => {
    const localDarkMode = localStorage.getItem("darkMode");
    return localDarkMode !== null ? localDarkMode === 'y' : prefersDarkMode;
  });

  const setDarkMode = useCallback(enabled => {
    if(enabled !== prefersDarkMode) { localStorage.setItem("darkMode", enabled) } 
    else { localStorage.removeItem("darkMode") }
    if(enabled !== darkMode) { setDarkMode_(enabled) }
  }, [ prefersDarkMode, darkMode ])

  useKeySequence(
    useMemo(() => ['D', 'D', 'S'], []), 
    useCallback(() => setDarkMode(!darkMode), [ darkMode, setDarkMode ]))
  
  // console.log(prefersDarkMode, colorMode)
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);
  return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </DarkModeContext.Provider>
}



ReactDOM.render(
  <React.StrictMode>
    <KeycloakProvider {...keycloakOptions}>
      <Root />
    </KeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();