import { useState, useEffect, useMemo, useCallback } from 'react';

import { useKeycloak, asUrl } from 'react-kcfetch';
import { useSearchParams } from 'react-router-dom';
// import useSWR from 'swr';

import { alpha, styled, css } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import NoiseReportForm from '../views/NoiseReport';
import DBChart from '../components/DBChart';

import { shortTimeString, useDeploymentId, useLocalStore, useKeycloakJSONSWR } from '../utils';
import { CenterBox, ButtonMenu } from '../components/utils';
import { timeQueries, apiUrl } from '../config'







const percentile = (x, percent) => {
    x.sort((a, b) => a - b);
    const p = (x.length - 1) * percent
    const i = Math.floor(p);
    return i < x.length-1 ? x[i] + (p-i)*(x[i+1] - x[i]) : x[i];
}


const DataView = ({ timeQueries }) => {
    const { keycloak, initialized, kcfetch } = useKeycloak();
    const deployId = useDeploymentId();
    const [search, setSearchParams] = useSearchParams();
    const log = search.get('log');
  
    const matches = useMediaQuery('(max-width:600px)');
    const matches280 = useMediaQuery('(max-width:280px)');
  
    // what time scale should we look at?
    // const [ timeQuery, setTimeQuery ] = useState(timeQueries[0]);
    let [ timeQuery, setTimeQuery ] = useLocalStore('sonyc:time-query', { defaultValue: timeQueries[0] });
    timeQuery = timeQuery || timeQueries[1];
    
    const interval = (matches && timeQuery.smallInterval) || timeQuery.interval

    // query and prepare the data
    let { data, error } = useKeycloakJSONSWR(
      timeQuery && timeQuery.query && deployId && 
        asUrl(`${apiUrl}/spl/${deployId}`, { start: `now-${timeQuery.query}`, interval: interval }), 
      // (url => new Promise(r => setTimeout(() => r(), 5000))),
      { refreshInterval: (timeQuery.refresh||30) * 1000, revalidateOnFocus: false });
    // let { data, error } = useKeycloakJSONSWR(
    //   initialized && deployId && `${apiUrl}/spl/${deployId}`, 
    //   { refreshInterval: (timeQuery.refresh||30) * 1000, revalidateOnFocus: false })
  
    if(error) console.error(error);
  
    // let data = [];
    
    data = useMemo(() => {
      if(!data || !data.length) return data;
      if(data[0].count) {
        return data.map(d => ({ ...d, laeq: d.avg_laeq, time: Date.parse(d.time) }))
      }
  
      return data.filter(d => d.laeq)
          .map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
          .sort((a, b) => a.time - b.time)
    
    }, [data])
    // const ts = data && data.map(d=>d.time);
    // console.log(ts && Math.min(...ts), ts && Math.max(...ts))
    // console.log(ts && new Date(Math.min(...ts)).toLocaleString(), ts && new Date(Math.max(...ts)).toLocaleString())
      console.log('SPL data', data)
  
    // compute data statistics
    const backgroundLevel = data && data.length && Math.round(percentile(data.map(d => d.laeq), 0.3));
    const peakLevel = data && data.length && Math.round(Math.max(...data.map(d => d.laeq)));
    
    // <Box display='flex' flexDirection='column' sx={{ overflow: 'auto' }}>
      //   <Box display='flex' flexDirection='column' flexGrow={1} justifyContent='center'>
    return initialized ? (<>
          <CenterBox sx={{ padding: '1em 0 0.25em' }} flexDirection={matches280 ? 'row' : 'column'} alignItems='center'>
            {matches280 ? (<>
              <ButtonMenu options={timeQueries.map(({ query, shortLabel }) => (
                { value: query, label: shortLabel }
              ))} 
              selected={timeQuery.query} 
              setSelected={v => setTimeQuery(timeQueries.find(o=>o.query===v) || timeQuery)} />
              <Box pl='0.5rem'>
                <Tooltip title='Background Level'>
                  <Typography><small><b>Bg:</b> {Math.round(backgroundLevel)} dB(A)</small></Typography>
                </Tooltip>
                <Tooltip title='Peak Level'>
                  <Typography><small><b>Peak:</b> {Math.round(peakLevel)} dB(A)</small></Typography>
                </Tooltip>
              </Box>
            </>) : (<>
              <Stack spacing={2} direction="row">
                {timeQueries.map((d, i) => 
                  <Button key={d.query} size='small' sx={{ height: '2.5em' }}
                    variant={timeQuery && d.query === timeQuery.query ? 'selected' : 'outlined'} 
                    onClick={e => setTimeQuery(timeQueries[i])}>{(matches && d.shortLabel) || d.label}</Button>)}
              </Stack>
              <CenterBox sx={{ padding: '0.25rem 0 0.5rem' }}>
                <StatCard sx={{ margin: matches?'0':'0 1em' }} fontSize={matches?10:16} title="Background Level" value={backgroundLevel} unit='dB(A)' />
                <StatCard sx={{ margin: matches?'0':'0 1em' }} fontSize={matches?10:16} title="Peak Level" value={peakLevel} unit='dB(A)' />
              </CenterBox>
            </>)}
          </CenterBox>

          <DBChart data={data} />
          <NoiseReportForm metadata={{ backgroundLevel, peakLevel }} defaultOpen={parseInt(log || 0)} buttonProps={{sx: { marginBottom: '2.5rem' }}} />
      
      </>) : null
}

DataView.defaultProps = { timeQueries }


const StatCard = ({ title, value, unit='dB(A)', fontSize=16, ...props }) => {
    return (
      <Card elevation={0} {...props}>
        <CardContent sx={{ padding: '0.5em 1em' }} style={{ paddingBottom: '0.5em' }}>
          <Typography sx={{ fontSize, fontWeight: 600 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography component="div" display='flex' alignItems='flex-end'>
            
            {value != null ? 
              <span style={{fontSize: fontSize*2, fontWeight: 700}} >{value}</span>  : 
              <Skeleton height={38} width={60}></Skeleton>}
            {unit && <span style={{fontSize, fontWeight: 600, marginLeft: '0.3rem', marginBottom: '0.4rem' }}> {unit}</span>}
          </Typography>
        </CardContent>
      </Card>
    );
}

export default DataView;