import SvgIcon from '@mui/material/SvgIcon';
import HeadphonesIcon from '@mui/icons-material/Headphones';

import { 
  SleepIcon, RestIcon, WalkIcon, ParentIcon, WorkIcon, HVACIcon,
  DeliveryIcon, GarbageIcon, NightlifeIcon, MusicIcon, ConstructionIcon,
  CookingIcon, StreetIcon
} from './icons'


export const helpEmail = 'bsteers@nyu.edu';

export const buttonIcons = {
  "Sleeping"      : <SvgIcon component={SleepIcon} />,
  "Leisure"       : <SvgIcon component={RestIcon} />,
  "Walking"       : <SvgIcon component={WalkIcon} />,
  "Parenting"     : <SvgIcon component={ParentIcon} />,
  "Working"       : <SvgIcon component={WorkIcon} />,
  "Construction"  : <SvgIcon component={ConstructionIcon} />,
  "Music"         : <SvgIcon component={MusicIcon} />,
  "Garbage"       : <SvgIcon component={GarbageIcon} />,
  "Delivery"      : <SvgIcon component={DeliveryIcon} />,
  "Bar"           : <SvgIcon component={NightlifeIcon} />,
  "Street"        : <SvgIcon component={StreetIcon} />,
  "Cooking"       : <SvgIcon component={CookingIcon} />,
  "HVAC"          : <SvgIcon component={HVACIcon} />,
  "Other"         : <HeadphonesIcon />
}


export const apiUrl = 'https://api.master1.sonycproject.com/api'


export const keycloakOptions = {
  url: 'https://auth.master1.sonycproject.com',
  realm: 'master',
  clientId: 'user',
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
};


export const timeQueries = [
  {query: '20m', label: '20 minutes', shortLabel: '20 min', refresh: 20},//, interval: '1m'
  {query: '90m', label: '90 minutes', shortLabel: '90 min', interval: '30s', refresh: 60},
  {query: '24h', label: '24 hours', shortLabel: '24 hr', interval: '2m', smallInterval: '5m', refresh: 180},
  {query: '72h', label: '72 hours', shortLabel: '72 hr', interval: '10m', smallInterval: '20m', refresh: 600},
]

export const audioTags = [
  // all lowercase !!!
  'truck', 
  'hvac', 
  'delivery', 
  'construction', 
  'fireworks', 
  'music', 
  'dog', 
  'street work', 
  'rain',
  // 'nothing of interest', 
]


export const mapStyleUrl = 'mapbox://styles/dwfries/ckbs2xw7b67891io27dvkzn0x'
export const mapDarkStyleUrl = 'mapbox://styles/mapbox/dark-v10'



export const mapboxToken = 'pk.eyJ1IjoiZHdmcmllcyIsImEiOiJjazlkMnFvNGIwOTV1M29yM2IxeDI4bHphIn0.kQk5hrC-EUxqzjlWxP43ew'
export const nycodToken = 'MznDCNh2accsEiW3siTNBcEAN';

export const noiseTypeKeys = {
    "Construction"  : [ "construction" ],
    "Bar"           : [ "commercial" , "bar"],
    "Street"        : [ "street" , "vehicle" ],
    "Delivery"      : [ "garbage" , "ice cream" ],
    "HVAC"          : [ "conditioner" , "ventilation" ],
    "Other"         : [  ]
}

// https://www1.nyc.gov/site/buildings/industry/permit-type-and-job-status-codes.page
export const permitTypes = {
    // 'AL' : 'Alteration',
    // 'EW' : 'Equipment Work',
    // 'EQ' : 'Construction Equipment',
    // 'PL' : 'Plumbing',
    'AL': 'Alteration',
    'FP': 'Fire Suppression',
    'DM': 'Demolition & Removal',
    'FS': 'Fuel Storage',
    'EQ': 'Construction Equipment',
    'MH': 'Mechanical/HVAC',
    'CH': 'Chute',
    'SD': 'Stand pipe',
    'FN': 'Fence',
    'SP': 'Sprinkler',
    'SH': 'Sidewalk Shed',
    'FO': 'Foundation/Earthwork',
    'SF': 'Scaffold',
    'FO': 'Earthwork Only',
    'EA': 'Earthwork Only',
    'OT': 'Other-General Construction, Partitions, Marquees, BPP (Builder Pavement Plan), etc',
    'NB': 'New Building',
    'EW': 'Equipment Work',
    'PL': 'Plumbing',
    'BL': 'Boiler',
    'SG': 'Sign',
    'FA': 'Fire Alarm',
    'FB': 'Fuel Burning',
}

export const bisJobCodes = {
    'A': 'Pre-Filed',
    'B': 'Application Processed - Part-No Payment',
    'C': 'Application Processed - Payment Only',
    'D': 'Application Processed - Completed',
    'E': 'Application Processed - No Plan Exam',
    'F': 'Application Assigned To Plan Examiner',
    'G': 'PAA Fee Due',
    'H': 'Plan Exam - In Process',
    'I': 'Sign-Off (ARA)',
    'J': 'Plan Exam - Disapproved',
    'K': 'Plan Exam - Partial Approval',
    'L': 'Pending Fee Estimation',
    'M': 'Fee Resolved',
    'P': 'Plan Exam - Approved',
    'Q': 'Permit Issued - Partial Job',
    'R': 'Permit Issued - Entire Job/Work',
    'U': 'Completed',
    'X': 'Signed-Off',    
    '3': 'Suspended',
}

export const dobNowCodes = {
    'AN': 'Antenna',
    'PL': 'Plumbing',
    'BE': 'Boiler Equipment',
    'PM': 'Protection and Mechanical Methods',
    'CC': 'Curb Cut',
    'SD': 'Standpipe',
    'EA': 'Earthwork',
    'SE': 'Support of Excavation',
    'EL': 'Electrical',
    'SF': 'Supported Scaffold',
    'FN': 'Construction Fence',
    'SG': 'Sign',
    'FO': 'Foundation',
    'SH': 'Sidewalk Shed',
    'SP': 'Sprinkler',
    'GC': 'Combined (GC + others)',
    'ST': 'Structural',
    'LA': 'Limited Alteration Applications (LAA)',
    'TA': 'Temporary Place of Assembly',
    'MS': 'Mechanical Systems',
    'VT': 'Elevator',
    'PA': 'Place of Assembly',
    'GC-CX': 'General Construction',
}






const config = { timeQueries, audioTags };
export default config;