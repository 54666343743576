import React, { useRef, useMemo, useCallback, useEffect, useState, createContext, useContext, useLayoutEffect } from 'react';

// import smoothscroll from 'smoothscroll-polyfill';

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useParams, useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom'
// import { makeStyles } from '@mui/material/styles';
// import { createMuiTheme, ThemeProvider } from '@mui/material';

import { 
  LineChart, XAxis, YAxis, Line, ResponsiveContainer, ReferenceArea, ReferenceLine, 
  CartesianGrid, Tooltip as ChartTooltip, Legend 
} from 'recharts';

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import Chip from '@mui/material/Chip'

// import AudioPlayer from 'material-ui-audio-player';
import { AudioPlayerProvider, useAudioPlayer, useAudioPosition } from "react-use-audio-player"


import IconButton from '@mui/material/IconButton'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Slider from '@mui/material/Slider'
import Skeleton from '@mui/material/Skeleton'

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

import MediaPlayer from '../components/MediaPlayer';
// import TagsInput from '../components/TagsInput';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

// import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';


// import NavBar from '../components/NavBar'
// import ButtonContainer from '../containers/ButtonContainer'
// import SonycSwitch from '../components/SonycSwitch';
// import InputWithTags from '../components/InputWithTags';

import DBChart from '../components/DBChart'
import { SonycSwitch, ReportCard, ReportError } from './NoiseReport'

// import useSWR from 'swr';
import { useKeycloak, asUrl } from 'react-kcfetch';
import { useDeploymentId, timeoutPromise, useKeycloakJSONSWR, shortTimeString } from '../utils';
// import { AutocloseSnackbar } from '../components/utils';

import { audioTags, apiUrl } from '../config';
import { useTheme } from '@mui/styles';

import deepEqual from 'deep-equal';

import { TagsInput, TagProvider } from '../components/UserInput';



const TagView = ({ reportData, files, splData, pageSize=5 }) => {
  const { kcfetch } = useKeycloak();
  // const [ offset, setPageOffset ] = useState(0);
  const [search, setSearch] = useSearchParams();

  const page = parseInt(search.get('page') || 1) - 1;
  const setPage = (i) => {
    setSearch({ page: i+1 })
  }
  const offset = page*pageSize;

  const { reportId } = useParams();

  // get report info
  reportData = useReport(!files && reportId, reportData);
  const { deployment_id: deployId, startTime, endTime } = reportData || {};

  // get list of files within report time frame
  files = useFiles(deployId, startTime, endTime, files);

  // get one page of files
  const pageFiles = useMemo(
    () => files && pageSize ? files.slice(offset, offset + pageSize) : files, 
    [ files, offset, pageSize ])  
  // const pageIndex = Math.floor(offset / pageSize)
  const pageTotal = Math.ceil(files?.length / pageSize)
  const areThereMore = page < pageTotal - 1;
  files && console.log(`page ${page + 1}/${pageTotal}:`, files, 'report:', reportData)

  splData = useSpl(deployId, startTime, endTime, splData)

  const submit = useCallback((data, end) => {
    if(!pageFiles) { console.error('no files to submit'); return; }
    data = pageFiles.map(f => ({ ...data[f.id], report_id: reportId })).filter(d => d.file_id)//.filter(d => d && d.played)
    console.log('Submitting:', data);//.then(r => r.json())

    return kcfetch(`${apiUrl}/annotations/${deployId}/${reportId}`, data).then(r => r.json()).then(r => {
      console.log('Submitted!', r, r.report_id);
      (end || !areThereMore) && setSayThankYou(true);
    })
  }, [ pageFiles, kcfetch, areThereMore, deployId, reportId ])

  const [ sayThankYou, setSayThankYou ] = useState(false)
  
  // console.log(files && files.map(f => [Date.parse(f.time + 'Z')+0, Date.parse(f.time + 'Z')+10*1000]))
  return <>
    {files && !files.length ? 
        <MessageCard 
            icon={<QuestionMarkIcon />}
            message='Hmm there seems to be no audio here.'
            body={<div>
              <Typography gutterBottom>
                This could happen if the data connection is slow, or if there is an issue on the server or sensor.
              </Typography>
              <Typography gutterBottom>
                Feel free to check back later from the Past Reports page. 
              </Typography>
            </div>} /> 
    : sayThankYou ? 
        <MessageCard message='All set! Thanks for your help.' icon={<DoneIcon />} /> 
    : 
        <TagProvider>
          <VerticalLinearStepper 
            files={pageFiles} submit={submit}
            getUrl={f => f.id && `${apiUrl}/file/id/${f.id}`} offset={offset} 
            nextPage={areThereMore ? (() => setPage(page + 1)) : null}
            end={() => setSayThankYou(true)} />
          {reportData && <Box sx={{ padding: '1em' }}>
            <ReportCard data={reportData} />
          </Box>}
        </TagProvider>
    }
    <DBChart data={splData} height={'40px'} disableAudioPlayer brush />
  </>
}


const stepStyles = {
  marginLeft: '0', 
  paddingLeft: '0',
  // paddingRight: '0',
  borderLeft: 'none',
}

const VerticalLinearStepper = ({ files, report, submit, getUrl, nextPage, end, offset=0 }) => {
  const [ activeStep, setActiveStep ] = useState(0);
  const nSteps = files ? files.length : 0;
  const goToStep = (i) => { setActiveStep(i) }
  const [ data, setData ] = useState({});

  useEffect(() => {
    const data = {}
    for(let d of files || []) {
      data[d.id] = data[d.id] || { file_id: d.id, relevant: false, tags: [] }
    }
    setData(data);
  }, [ files ])

  return (
    <Box display='flex' flexDirection='column' alignItems='stretch' pt={3} px={2} sx={{
      // width: '100vw',
      // maxWidth: '800px',
      // margin: 'auto',
    }}>
      {files ? (<Stepper activeStep={activeStep} orientation="vertical">
          {files.map((f, i) => (
          <Step key={i}>
            <StepLabel 
              // sx={{ '& .Mui-completed': { color: 'secondary.dark' } }}
              // onClick={activeStep >= nSteps ? (() => goToStep(i)) : null}
              onClick={() => goToStep(i)}
            // optional={index === 2 ? (<Typography variant="caption">Last step</Typography>) : null}
              >
              Clip <b>{offset + i+1}</b> of {offset + nSteps}
              {' '}&#8212;{' '}
              {!isNaN(new Date(f.time)) && new Date(f.time).toLocaleTimeString()}
              {' '}&#8212;{' '}
              Peak: {f?.max_laeq ? Math.round(f.max_laeq) : '-'} dB(A)
              {' '}
              {data[f.id]?.relevant && <Chip label='Yes' size="small" sx={{ mx: 0.3, backgroundColor: 'primary.main', color: 'background.default' }} />}
              {(ts => (ts && ts.map(t => <Chip label={t} size="small" sx={{ mx: 0.3, borderColor: 'primary.main' }} variant="outlined" />)))(data[f.id]?.tags)}
            </StepLabel>
            <StepContent sx={stepStyles}>
              <AudioTagCard 
                file={f} annotation={data[f.id]} 
                loadOnPlay={i > activeStep + 1} 
                onSave={() => { goToStep(i+1) }}
                goToNext={i < nSteps ? (() => goToStep(i+1)) : null}
                goToPrevious={i > 0 ? (() => goToStep(i-1)) : null}
                />
            </StepContent>
          </Step>
        ))}
        <Step>
            <StepLabel onClick={() => goToStep(nSteps)}>
              Finish
            </StepLabel>
            <StepContent sx={stepStyles}>
            <ThanksEndCard submit={submit} data={data} nextPage={nextPage ? () => { nextPage(); goToStep(0) } : null} end={end} back={() => goToStep(nSteps-1)} />
            </StepContent>
        </Step>
        
      </Stepper>) : (<>
              <Skeleton width='60vw' height={24} />
              <Skeleton width='60vw' height='150px' variant='rectangle' />
        </>)}
    </Box>
  );
}


const AudioTagCard = ({ fileId: fid, file: fileData, next, children, buttons, onSave, reportId, goToNext, goToPrevious, ...props }) => {
  let [ editing, setEditing ] = useState(null);

  const { reportId: reportIdParam } = useParams();
  reportId = reportId || reportIdParam;

  let { data, annotation, file, url, update, save, clear, saveStatus } = useAudioAnnotation({ 
    fileId: fid, reportId,
    // annotation: { relevant: false, tags: ['construction', 'ducks']}, 
    file: fileData,
    onSave,
  });

  editing = editing === null ? !annotation : editing;

  const { avg_laeq, max_laeq, time } = file || {};
  const { relevant=false, tags=[], time: tagTime=null } = data || {};
  // console.log(data, annotation)

  return (<>
        <Card variant='outlined' sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px', minWidth: '270px' }}>
          <AudioPlayer 
            src={url} 
            title={<>{new Date(time).toLocaleTimeString()} {'  '}<small>{new Date(time).toLocaleDateString()}</small></>}
            artist={`Average: ${avg_laeq ? Math.round(avg_laeq) : '-'} dB(A), Peak: ${max_laeq ? Math.round(max_laeq) : '-'} dB(A)`}
            sx={{ borderRadius: '16px 16px 0 0' }}
            noVolume howlerOptions={{
              onplay: e => {
                // console.log('playing', url)
                // data && !data?.played && updateData({ played: true });
              },
            }} {...props} />
          <CardContent>
            {saveStatus?.error &&
            <Typography>
              Error: {saveStatus?.code} {saveStatus?.message}
            </Typography>}
            {saveStatus?.uploading ? 
              <Skeleton />
            : 
            !editing && annotation ? <>
              {tagTime && <Typography variant='subtitle2' gutterBottom color='primary' display='block'>
                You tagged this clip at: <code>{new Date(Date.parse(tagTime)).toLocaleString()}</code>
              </Typography>}
              <Stack spacing={1} direction='row' alignItems='center'>
                <Chip label={relevant ? 'Yes this is it!' : 'This is not it.'} variant='filled' color={relevant ? "success" : 'primary'} />
                {tags && tags.map(tag => <Chip label={tag} key={tag} variant='outlined' />)}
              </Stack>
              
            </> : <>
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography variant='h6' gutterBottom sx={{  }}>
                    Is this the sound your complaint is about?
                </Typography>
                <SonycSwitch 
                    checked={relevant} 
                    onChange={e => {
                      update({ ...data, relevant: e.target.checked })
                    }} />
              </Stack>

              {relevant && (<>
                <Typography variant='h6'>
                    Describe the sounds in this audio clip:
                </Typography>
                <TagsInput available={audioTags} selected={tags}
                  setTag={(tag, value=true) => {
                    if(value) {
                      if(!tags || !tags.includes(tag)) {
                        update({ ...data, tags: [ ...tags, tag ] })
                      }
                    } else {
                      if(tags && tags.includes(tag)) {
                        update({ ...data, tags: tags.filter(t => t !== tag) })
                      }
                    }
                  }} />
              </>)}
            </>}
            {children}
          </CardContent>
          <CardActions>
            {editing ? 
              <Button variant='outlined' onClick={() => { save(); setEditing(false) }} disabled={!save}>Confirm</Button>
              :
              <Button variant='outlined' onClick={() => setEditing(true)}>Edit</Button>
            }
            {editing && annotation && <Button size='small' onClick={() => setEditing(false)}>Cancel</Button>}
            {editing && clear && <Button size='small' onClick={() => clear()}>Clear</Button>}
            {!editing ? <>
              {goToNext && <Button size='small' onClick={() => { goToNext() }}>Next</Button>}
              {goToPrevious && <Button size='small' onClick={() => { goToPrevious() }}>Back</Button>}
            </> : null}
            {buttons}
          </CardActions>
        </Card>
  </>)
}



const useAudioAnnotation = ({ fileId, reportId, file, annotation, onSave, closed=false }) => {
  const { kcfetch } = useKeycloak();

  // get file metadata
  let { data: fileQuery, error: fileError } = useKeycloakJSONSWR(
    !file && asUrl(`${apiUrl}/file/id/${fileId}/meta`), 
    { refreshInterval: 0, revalidateOnFocus: false })
  if(fileError) console.error(fileError);
  file = file || fileQuery;
  fileId = fileId || file?.id;
  const fileTime = file?.time;

  // if there's no data
  const defaultData = useMemo(() => ({ relevant: false, tags: [] }), [])
  // get existing annotation
  let { data: annQuery, error: annError } = useKeycloakJSONSWR(
    !annotation && asUrl(`${apiUrl}/annotations/file/${fileId}`), 
    { refreshInterval: 0, revalidateOnFocus: false })
  if(annError) console.error(annError);
  annotation = annotation || (annQuery?.length ? 
    annQuery[annQuery.length-1]
    // annQuery.reduce((r, d) => r.time > d.time ? r : d, {time: 0})
  : null);

  // the local unsaved changes
  const [ updated, setUpdated ] = useState();
  // the local saved changes
  const [ modified, setModified ] = useState();
  // local unsaved, local saved, remote saved
  annotation = updated || annotation;
  const data = modified || annotation || defaultData;
  // bypass dependency array
  const dataRef = useRef();
  dataRef.current = modified;

  const [ saveStatus, setSaveStatus ] = useState(null);

  const update = useCallback(d => {
    // console.log('update', data, d)
    setModified({ tagTime: data?.tagTime || new Date(Date.now()).toISOString(), ...data, ...d })
  }, [data])
  const clear = () => { console.log('clear', modified); setModified(null) }

  const save = useCallback(({ unmounting }={}) => {
    const data = { 
      time: new Date(Date.now()).toISOString(),
      ...dataRef.current,
      reportId,
      fileId,
      fileTime,
    };
    console.log('saving', !deepEqual(data, annotation), data, annotation)
    if(!deepEqual(data, annotation)) {
      console.log('saving', `${apiUrl}/annotations/file/${fileId}`, data)
      setSaveStatus({ uploading: true });
      kcfetch(`${apiUrl}/annotations/file/${fileId}`, {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
      }).then(d => d.json()).then(d => {
        onSave(data, d, fileId, unmounting);
        if(!unmounting) {
          setUpdated(data);
          setModified(null);
          setSaveStatus({ success: true, response: d });
        }
      }).catch(e => {
        setSaveStatus({ error: true, ...e });
        console.error(e)
      })
    }
  }, [ kcfetch, fileId, fileTime, onSave, reportId, annotation ]);
  // const saveRef = useRef();
  // saveRef.current = save;
  
  // useEffect(() => {
  //   return () => {
  //     console.log('exiting')
  //     if(dataRef.current) { saveRef.current(true); }
  //   }
  // }, [])

  fileId = file?.id || fileId;
  const url = fileId && `${apiUrl}/file/id/${fileId}`;
  return { annotation, data, file, fileId, url, update, clear, save, saveStatus };
}


const useReport = (reportId, reportData) => {
  let { data: reportQueryData, error: reportQueryError } = useKeycloakJSONSWR(
    !reportData && reportId && 
      `${apiUrl}/reports/id/${reportId}`, 
    { refreshInterval: 0, revalidateOnFocus: false })

  if(reportQueryError) console.error(reportQueryError);
  reportData = reportData || reportQueryData;

  if(reportData) {
    reportData.startTime = reportData?.startTime || reportData?.duration?.[0] || null;
    reportData.endTime = reportData?.endTime || reportData?.duration?.[1] || null;
  }
  return reportData;
}

const useFiles = (deployId, startTime, endTime, files) => {
  let { data: filesQuery, error: filesError } = useKeycloakJSONSWR(
    !files && startTime && endTime && 
      asUrl(`${apiUrl}/file/audio/${deployId}`, { start: startTime, end: endTime, interesting: 1 }), 
    { refreshInterval: 0, revalidateOnFocus: false })
  files = files || filesQuery;
  if(filesError) console.error(filesError);

  files = useMemo(() => {
    return files && files.map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
  }, [files])
  return files
}

const useSpl = (deployId, startTime, endTime, splData) => {
  let { data: splQueryData, error: splError } = useKeycloakJSONSWR(
    !splData && deployId && asUrl(
      `${apiUrl}/spl/${deployId}`, 
      { start: startTime, end: endTime }), 
    { refreshInterval: 0, revalidateOnFocus: false });
  if(splError) console.error(splError);
  splData = splData || splQueryData;
  
  splData = useMemo(() => {
    if(!splData || !splData.length) return splData;
    if(splData[0].count) {
      return splData.map(d => ({ ...d, laeq: d.avg_laeq, time: Date.parse(d.time) }))
    }

    return splData.filter(d => d.laeq)
        .map(d => ({ ...d, time: Date.parse(d.time + 'Z') }))
        .sort((a, b) => a.time - b.time)
  }, [splData])
  return splData;
}


const AudioPlayer = ({ src, dontLoad, ...props }) => {
  // console.log(src)
  return <AudioPlayerProvider>
      {/* <AudioControls {...p} /> */}
      <MediaPlayer src={dontLoad ? null : src} {...props} />
      {/* <Spectrogram /> */}
  </AudioPlayerProvider>
}

const ThanksEndCard = ({ submit, data, nextPage, end, back }) => {
  const [ saving, setSaving ] = useState(false);
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} sx={{ padding: '0.5em' }}>

      {saving === true ? (
        <Box display='flex' justifyContent='center' sx={{ padding: '0' }}>
        <CircularProgress color="primary" size={80} />
      </Box>
      ) : saving ? (<ReportError error={saving} closeTo='/data' submit={() => {
          setSaving(true);
          (submit(data, saving.finish)
              .then(d => { setSaving(false); (saving.finish ? end() : nextPage()) })
              .catch(error => { setSaving({ error, data }) }));
      }} subject={"SONYC Home Audio Annotations Error"} />) : (<>
      <Box sx={{ backgroundColor: 'background.light', borderRadius: '100%', padding: '1em' }}>
        <DoneIcon />
      </Box>
      <Typography variant='h3' gutterBottom align='center'>
        Thanks!
      </Typography>
      <Typography gutterBottom align='center'>
        Finding examples of the noise is very useful in documenting the issue.
      </Typography>
      <Stack spacing={2} direction="column">
      <Stack spacing={1} direction='row' sx={{ padding: '1em' }}>
        <Tooltip title="Save and go back to your sensor!">
          <Button variant='contained' onClick={() => { end() }}>Finish</Button>
        </Tooltip>
        {nextPage && <Tooltip title="Let's look through more clips!">
          <Button onClick={() => { nextPage() }}>Load More</Button>
        </Tooltip>}
        {back && <Tooltip title="I want to look through the clips I just annotated.">
          <Button onClick={() => { back() }}>Go back</Button>
        </Tooltip>}
        </Stack>
      </Stack>
      </>)}
    </Box>
  )
}


const MessageCard = ({ message, icon, body, buttons, iconColor }) => {
  const theme = useTheme();
  // '#44f059'
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} sx={{ padding: '2em 2em 40vh' }}>
      {icon && <Box sx={{ backgroundColor: theme.palette.background[iconColor] || theme.palette.background.dark, borderRadius: '100%', padding: '1em' }}>
        {icon}
      </Box>}
      <Typography variant='h3' gutterBottom align='center'>
        {message}
      </Typography>
      {body}
      <Stack spacing={2} direction="column">
        {buttons}
      <Button component={Link} to='/data' size='small'>Return to data view.</Button>
    </Stack>
    </Box>
  )
}


export default TagView
