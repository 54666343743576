// import React from 'react';
// import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from "@mui/material/styles";
import { ReactComponent as SonycLogoSvg } from './assets/SONYC_logo.svg';

const ThemeFill = ({ component, ...props }) => {
    const theme = useTheme();
    const Component = component;
    return <Component fill={theme.palette.text.primary} {...props} />
}

export { ReactComponent as MarkerIcon } from './assets/markers/marker.svg';
export { ReactComponent as LocationIcon } from './assets/icons/user-icon.svg';

export { ReactComponent as SleepIcon } from './assets/icons/Sleeping.svg';
export { ReactComponent as RestIcon } from './assets/icons/Resting.svg';
export { ReactComponent as WalkIcon } from './assets/icons/Walking.svg';
export { ReactComponent as ParentIcon } from './assets/icons/Parenting.svg';
export { ReactComponent as WorkIcon } from './assets/icons/Working.svg';
export { ReactComponent as CookingIcon } from './assets/icons/Cooking.svg';

export { ReactComponent as HVACIcon } from './assets/icons/HVAC.svg';
export { ReactComponent as StreetIcon } from './assets/icons/Street.svg';
export { ReactComponent as DeliveryIcon } from './assets/icons/delivery1.svg';
export { ReactComponent as GarbageIcon } from './assets/icons/garbage1.svg';
export { ReactComponent as NightlifeIcon } from './assets/icons/nightlife.svg';
export { ReactComponent as MusicIcon } from './assets/icons/music.svg';
export { ReactComponent as ConstructionIcon } from './assets/icons/const4.svg';

export { ReactComponent as PinpointIcon } from './assets/icons/pinpoint4.svg';
export { ReactComponent as MenuIcon } from './assets/icons/burgermenu.svg';

export { ReactComponent as DobIcon } from './assets/logos/nycdob.svg'
export { ReactComponent as Nyc311Icon } from './assets/logos/nyc311.svg'
export { ReactComponent as DotIcon } from './assets/logos/nycdot.svg'

export const SonycLogo = ({ ...props }) => <ThemeFill component={SonycLogoSvg} {...props} />