import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import SonycLogoSvg from './assets/SONYC_logo.svg';

import { BrowserRouter, Routes, Route, Navigate, NavLink, Link, useParams, useNavigate, useLocation, matchPath } from "react-router-dom";

import { Protect, Login, Logout, useKeycloak, asUrl } from 'react-kcfetch';
// import useSWR from 'swr';

import { alpha, styled, css } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';

import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

import LogoutIcon from '@mui/icons-material/Logout';
// import FlagIcon from '@mui/icons-material/Flag';
import HearingIcon from '@mui/icons-material/Hearing';
import TimelineIcon from '@mui/icons-material/Timeline';
import SensorsIcon from '@mui/icons-material/Sensors';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EmailIcon from '@mui/icons-material/Email';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WebIcon from '@mui/icons-material/Web';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import AudioTagView from './views/AudioTagPage';
import NoiseReportForm from './views/NoiseReport';
import ReportView from './views/ReportsPage';
import DataView from './views/DataPage';
import MapView from './views/Map';
import DesignView from './views/DesignSpec';
import DataExploreView from './views/DataExploration';
import AudioClipView from './views/AudioClipPage';

import { SonycLogo } from './icons';
import { useDeploymentId, useDeployId, useLocalStore, useKeycloakJSONSWR, DeployIdProvider } from './utils';
import { CenterBox } from './components/utils';
import { useColorMode } from './theme';
import { apiUrl } from './config';


// Data.js



// the true App.js


// const ripple = ({ theme }) => keyframes
//   0% {
//     box-shadow: 0 0 0 0 rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 20px rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 40px rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 60px rgba(${theme.palette.primary.main}, 0.1);
//   }
//   100% {
//     box-shadow: 0 0 0 20px rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 40px rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 60px rgba(${theme.palette.primary.main}, 0.1),
//                 0 0 0 80px rgba(${theme.palette.primary.main}, 0);
//   }
// 

/* animation: ${ripple({ theme })} 1s linear infinite; */
const LogoButton = styled(ButtonBase)(({ theme }) => `
  width: 220px;
  height: 220px;
  align-self: center;
  background-color: ${theme.palette.primary.main} !important;
  background: linear-gradient(45deg, #2d1e8e, transparent);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
`);

// const SonycLogo = ({ dark, style, ...p }) => {
//   const theme = useTheme()
//   console.log(dark, theme.palette.mode)
//   dark = dark === undefined ? theme.palette.mode !== 'dark' : dark;
//   return <img src={SonycLogoSvg} alt="SONYC Logo" {...p} 
//     style={{ filter: dark ? 'invert(100%)' : null, ...style }}></img>
// }
const SonycButton = ({ ...props }) => (
  <LogoButton variant="contained" color="primary" disableelevation="true" {...props}>
    <SonycLogo width='80%' fill='white' />
  </LogoButton>)


const HomeButtonPage = () => {
  const { keycloak, initialized } = useKeycloak();
  const token = keycloak && keycloak.token;

  return (
    // !!token ? <Navigate to="/data" /> : 
    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'
         sx={{ height: '100%', padding: '4em 1em 8em', textAlign: 'center' }}>{(
    initialized ? 
      <NavLink to="/login"> 
        <SonycButton onClick={() => console.log('clicked button')} />
      </NavLink> : 
      <SonycButton  onClick={() => console.log('clicked button and not initialized')} />
    )}
    <Typography variant='h2' gutterBottom sx={{ paddingTop: '1em' }}>
    Welcome!
    </Typography>
    <Typography variant='h4' gutterBottom>
    Click the SONYC button to get started.
    </Typography>

    <InstallPrompt />

    
    
    </Box>
  )
}


const InstallPrompt = () => {

  // const [ bie, setBie ] = useState();
  // useEffect(() => {
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();
  //     // Stash the event so it can be triggered later.
  //     // deferredPrompt = e;
  //     // // Update UI notify the user they can install the PWA
  //     // showInstallPromotion();
  //     // Optionally, send analytics event that PWA install promo was shown.
  //     console.log(`'beforeinstallprompt' event was fired.`, e);
  //     setBie(e)
  //   });
    
  // })
  if (navigator.standalone) { return null; }
  const iOS = ['iPhone', 'iPad', 'iPod'].includes(navigator?.userAgentData?.platform || navigator?.platform);
  if (!iOS) { return null; }
  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', '.MuiSvgIcon-root': { verticalAlign: 'middle' } }}>
      <Typography variant='h6'>
      <b>Install app</b>: <IosShareIcon sx={{ verticalAlign: 'text-bottom' }} /> then <AddBoxOutlinedIcon /> Add to Home Screen
      </Typography>
      </Toolbar>
    </AppBar>
  )
}


//   </Box>
    // </Box>



// const Error404 = () => {
//   return (
//     <div style = {{marginTop: '4em', marginLeft: '1.5em', marginRight: '1.5em', flex: 0, display: 'flex', flexDirection: 'column', textAlign: 'left', height: '100vh'}}>
//       <p style = {{fontSize: 16, fontWeight: 600}} >404</p>
//       <p style = {{fontSize: 32, fontWeight: 700}} >There's no page at this address...</p>
//       <NavLink style={{}} to='/'>
//       <p style = {{fontSize: 16, fontWeight: 600}} >Click here to return to the landing page.</p>
//       </NavLink>
//     </div>
//   )
// }

const Error404 = ({ reportId, setOpen }) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} sx={{ padding: '2em 2em 4em' }}>
      <SonycLogo style={{ width: '15em', maxWidth: '80vw' }} />
      <Typography variant='h3' gutterBottom align='center'>
        <b>404</b>: Looks like you're lost..
      </Typography>
      <Typography variant='h5' gutterBottom align='center'>
        No worries, we'll get you back.
      </Typography>
      <Stack spacing={2} direction="column">
        <Button component={Link} to={`/data`} size='small'>Return to data view.</Button>
      </Stack>
    </Box>
  )
}

// const variableMinHeight = isSafari ? '-webkit-fill-available' : '100vh'

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const isSafari = /Safari/.test(navigator.userAgent)
// const webkit = !!ua.match(/WebKit/i);
// const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
const isSafariDesktop = isSafari && /Apple Computer/.test(navigator.vendor);
// console.log(ua, iOS, isSafari, isSafariDesktop)

function App() {
  const { initialized } = useKeycloak();
  return (
    <Paper className="App" style={{ 
        height: isSafari && iOS ? '-webkit-fill-available' : '100vh', //variableMinHeight, 
        // height: '-webkit-fill-available',
        minHeight: '0',
        // maxHeight: iOSSafari ? '-webkit-fill-available' : '100vh', //variableMinHeight, 
        maxHeight: '100vh',
        // overflow: 'hidden', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'stretch',
        borderRadius: 0,
        overflowY: 'auto',
    }}>
      <DeployIdProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path = '/' element={<HomeButtonPage />} />
          {/* {deployRoutes({ path: '/data', title: 'Sound Pressure Level (SPL)', children: <DataView /> })}
          {deployRoutes({ path: '/reports', title: 'Previously Logged', children: <ReportView /> })} */}

          <Route path='/data'>
            <Route path="" element={<Page title='Sound Pressure Level (SPL)' path={'/data'}>{<DataView />}</Page>} />
            <Route path=":deployId" element={<ChangeSensorRedirect to='/data' />} />
          </Route>

          <Route path='/reports'>
            <Route path="" element={<Page title='Previously Logged' path={'/reports'}>{<ReportView />}</Page>} />
            <Route path=":deployId" element={<ChangeSensorRedirect to='/reports' />} />
          </Route>

          {/* <MultiRoutes path='/data' element={<Page title='Sound Pressure Level (SPL)' path={'/data'}>{<DataView />}</Page>}>
            {element => (<>
              <Route path=':deployId' element={element} />
            </>)}
          </MultiRoutes>

          <MultiRoutes path='/reports' element={<Page title='Previously Logged' path={'/reports'}>{<ReportView />}</Page>}>
            {element => (<>
              <Route path=':reportId' element={element} />
            </>)}
          </MultiRoutes> */}

          <Route path="/audio/:fileId" element={<Page title='Audio'>
            <AudioClipView />
          </Page>} />
          <Route path="/tag/:reportId" element={<Page title='Audio Tagging'>
            <AudioTagView />
          </Page>} />
          <Route path='/map' element={
            initialized && <>
              <NavBar title='Near Me' path='/map' />
              <MapView noiseType={'Construction'} showOpeningDialog={false} />
            </>} />
          
          <Route path="/design" element={<DesignView />} />
          <Route path="/dora" element={<DataExploreView />} />
          <Route path="/dora/:deployId" element={<DataExploreView />} />
          
          {/* <Route path="/" element={<App />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="invoices" element={<Invoices />} /> */}
          <Route path="/cycle" element={<Cycler />} />
          <Route path="/login" element={<Login redirectUri='/data' />} />
          <Route path="/logout" element={<Logout redirectUri="/" />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      </DeployIdProvider>
    </Paper>
  );
}

/* testing for audio tag page */
const Cycler = ({ timeout=1000 }) => {
  const boxRef = React.useRef(null);
  const [ j, setJ ] = useState(0);
  const [ direction, setDirection ] = useState(1)
  const xs = [0, 1, 2, 3, 4, 5]
  const inc = (x=1) => { setJ(Math.min(Math.max(0, j+x), xs.length-1));setDirection(x) }
  // <Collapse key={i} orientation='horizontal' in={i === j} mountOnEnter unmountOnExit timeout={timeout}>
  return <Box ref={boxRef} display='flex' direction='row' justifyContent='center' sx={{ width: '100%', maxWidth: '40em', margin: 'auto', border: '2px solid white' }}>
    {xs.map(i => 
    i === j && 
      <Fade key={i} 
        // direction={direction >= 0 ? 'left' : 'right'} 
        container={boxRef.current} in={i === j} timeout={timeout} unmountOnExit>
      <Box>
        {/* <Fade in={i === j} timeout={timeout}> */}
        <Box sx={{ backgroundColor: 'blue', margin: '1em' }}>
          <h1>{i}</h1>
          <Button onClick={() => inc(-1)}>- 1</Button>
          <Button onClick={() => inc(+1)}>+ 1</Button>
        </Box>
        {/* </Fade> */}
      </Box>
    </Fade>)}
  </Box>
}

const ChangeSensorRedirect = ({ to }) => {
  const [ _, setDeployId ] = useDeployId();
  const { deployId } = useParams();
  useEffect(() => { setDeployId(deployId) })
  return <Navigate to={to} replace />
}

const deployRoutes = ({ path, children, title, routes, open }) => {
  children = <Page title={title} open={open} path={path}>{children}</Page>
  return (
    <Route path={path}>
      <Route path="" element={children} />
      {routes}
      <Route path="/:deployId" element={children} />
    </Route>
  )
}

const MultiRoutes = ({ path, children, element }) => {
  return (
    <Route path={path}>
      <Route path="" element={children} />
      {children(element)}
    </Route>
  )
}


const Page = ({ title, children, open, path }) => {
  const content = <>
    <NavBar title={title} path={path} />
    {/* <Box display='flex' flexDirection='column' flexGrow={1} flexShrink={1} sx={{ overflow: 'auto' }}> */}
    {children}
    {/* </Box>  */}
  </>
  return open ? content : <Protect unprotected={<Login returnHere />}>
    {/* */}
    {content}
  </Protect>
}



const NavBar = ({ title, siteTitle='SONYC Home', path='/data' }) => {
  const [ open, setOpen ] = useState(false);
  const { deployId } = useParams();
  const { darkMode, setDarkMode } = useColorMode();
  const { pathname } = useLocation();
  useEffect(() => {
    document.title = title ? `${title} | ${siteTitle}` : siteTitle
    return (() => { document.title = siteTitle })
  }, [ title, siteTitle ])

  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar position="sticky" color='transparent' elevation={1}>
        <Toolbar sx={{ mr: 6 }}>
          <IconButton onClick={() => setOpen(o => !o)}
              size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Stack flexGrow={1} alignItems='center'>
            {/*  spacing={2} direction="row" justifyContent='center' alignItems='center' */}
            <SonycLogo width='85px' height='30px' style={{ paddingLeft: '1em' }} />
            {/* TODO: why is the scaling so weird? - preserve aspect ratio */}
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              {title}
            </Typography>
          </Stack>
          
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
      <List>
        <ListItem button dense onClick={() => setOpen(false)}>
          <ListItemIcon><ChevronLeftIcon /></ListItemIcon>
          <ListItemText primary='' />
        </ListItem>
        <ListItem sx={{ display: 'flex', justifyContent: 'center' }} component={Link} to="/">
          <SonycLogo width="80%" style={{ paddingLeft: '1em' }} />
        </ListItem>
        
        <Divider sx={{ margin: '0.25em 0' }} />

        <List>
          <ListItemButton onClick={() => setOpen(false)} component={Link} to={deployId ? `/data/${deployId}` : '/data'} selected={pathname && !!matchPath('/data/*', pathname)}>
            <ListItemIcon><TimelineIcon /></ListItemIcon>
            <ListItemText primary='Data' />
          </ListItemButton>
          <ListItemButton onClick={() => setOpen(false)} component={Link} to={deployId ? `/reports/${deployId}` : '/reports'} selected={pathname && !!matchPath('/reports/*', pathname)}>
            <ListItemIcon><NoteAltIcon /></ListItemIcon>
            <ListItemText primary='Past Reports' />
          </ListItemButton>
        </List>

        <Divider sx={{ margin: '0.5em 0' }} />
        
        <List subheader={<ListSubheader color='inherit'>Useful Links</ListSubheader>}>
          <ListItem button component='a' href='https://wp.nyu.edu/sonyc/faq/' target='_blank'>
            <ListItemIcon><QuestionAnswerIcon /></ListItemIcon>
            <ListItemText primary='FAQ' />
          </ListItem>
          <ListItem button component='a' href='https://wp.nyu.edu/sonyc/' target='_blank'>
            <ListItemIcon><WebIcon /></ListItemIcon>
            <ListItemText primary='SONYC Website' />
          </ListItem>
          <ListItem button component='a' href='https://wp.nyu.edu/sonyc/contact/' target='_blank'>
            <ListItemIcon><EmailIcon /></ListItemIcon>
            <ListItemText primary='Contact Us' />
          </ListItem>
        </List>

        {/* <Divider sx={{ margin: '0.25em 0' }} />

        <List>
          <ListItem button onClick={() => setDarkMode(!darkMode)}>
            <ListItemIcon>
              <IconButton color="inherit">
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={`${darkMode?'dark':'light'} mode`} />
          </ListItem>
        </List> */}

      <List subheader={<ListSubheader color='inherit'>Settings</ListSubheader>}>
        <ListItem>
          <ListItemIcon><EdgesensorHighIcon /></ListItemIcon>
          <DeploymentIdMenu path={path} />
        </ListItem>
        <ListItem button onClick={() => setDarkMode(!darkMode)}>
            <ListItemIcon>
              <IconButton color="inherit">
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={`${darkMode?'dark':'light'} mode`} />
          </ListItem>
        </List>

        <Divider sx={{ margin: '0.5em 0' }} />
        
        <List>
          <ListItem button component={Link} to='/logout'>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
        </List>
      </Drawer>
    </Box>
  );
}

const DeploymentIdMenu = ({ }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = e => setAnchorEl(null)
  const [ depId, setDeployId, myDeployId ] = useDeployId();

  // const { kcfetch, initialized } = useKeycloak();
  // const depId = useDeploymentId();
  let { data, error } = useKeycloakJSONSWR(
    `${apiUrl}/deployments`, 
    { refreshInterval: 0, revalidateOnFocus: false })
  if(error) console.error(error);
  // console.log(data, error)

  // const location = useLocation()
  // console.log(location)
  // const navigate = useNavigate()
  // console.log(depId, myDeployId, data)

  return data && data.length > 1 ? (<div>
    <Typography>
      Sensor:
    </Typography>
    <Button onClick={e => setAnchorEl(e.currentTarget)}>{depId}</Button>
    <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
          {data && data
            .filter(d => d.start_time && d.life_stage==='Active')
            .sort((a, b) => (Date.parse(b.time)||0) - (Date.parse(a.time)||0)).map(d => 
              <MenuItem 
                // component={Link} to={`${path}/${d.deployment_id}`}
                key={d.deployment_id} selected={depId === d.deployment_id} 
                onClick={e => { handleClose(e); setDeployId(d.deployment_id) }}>{d.title || d.deployment_id}</MenuItem>)}
      </Menu>
      {myDeployId && myDeployId !== depId ? <Button sx={{ display: 'block' }} onClick={() => setDeployId(null)}>My Sensor</Button> : null}
  </div>
    
  ) : null
}



export default App;
