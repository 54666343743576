import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useKeycloak } from 'react-kcfetch';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDeployId } from '../utils';
import { helpEmail } from '../config';


export const ErrorReportButton = ({ subject, message, lines, email, error, data, label='Give us a shout' }) => {
  const { keycloak } = useKeycloak();
  const [ deployId ] = useDeployId();
  const username = keycloak?.tokenParsed?.preferred_username;
  lines = [
    `Submitted by: ${username}`,
    `Deployment ID: ${deployId}`,
    `Page: ${window.location.href}`,
    '',
    message || null,
    ...lines||[],
    '',
    data ? `Associated Data: ${JSON.stringify(data)}` : null,
    error ? `Error Message: ${JSON.stringify(error)}` : null,
  ].filter(l => l != null);
  return <Button
    variant="contained" rel="noopener noreferrer" target='_blank'
    href={`mailto:${email || helpEmail}?subject=${deployId}: ${subject}&body=${lines.join('%0D').replace('\n', '%0D')}`}>
    {label}
  </Button>
}




export const ButtonSelect = ({ title, options, selected, setSelected, tall, stretch }) => {
    return (<Box sx={{ margin: '0.5em' }}>
      {title && <Typography variant='h6'>{title}:</Typography>}
      <Stack direction="row" justifyContent={stretch?'stretch':'center'} flexWrap='wrap'>
          {options.map(({ value, icon, label, ...p }, i) => 
            <Button key={value} startIcon={icon} 
              sx={{ flexGrow: 1, flexShrink: 0, height: tall ? '3.5em' : '2.5em', textTransform: 'none', margin: '0.1em' }}
              variant={value === selected ? 'selected' : 'outlined'}
              onClick={e => setSelected(value)} {...p}>{label || value}</Button>)}
        </Stack>
    </Box>)
    // variant={d.value === selected ? 'contained' : 'outlined'} 
    // return (
    //   <Card elevation={0}>
    //     <CardContent>
      
    //     </CardContent>
    //   </Card>)
  }
  
  export const ButtonMenu = ({ title, options, selected, setSelected, noValue='--' }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    return (
      <div>
        {title && <Typography variant='h6'>{title}:</Typography>}
        <Button onClick={(event) => { setAnchorEl(event.currentTarget) }} variant={selected ? 'selected' : 'outlined'}>
          {options.find(o=>o.value === selected)?.label || selected || noValue}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          {options.map(({ value, icon, label, ...p }, i) => 
            <MenuItem key={value} onClick={() => {
              setAnchorEl(null);
              setSelected(value);
            }}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>{label || value}</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
  
  
  
  export const CenterBox = ({ children, sx, ...props }) => {
    return <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', ...sx }} {...props}>{children}</Box>
  }
  

  export const AutocloseSnackbar = ({ ...props }) => {
    const [ open, setOpen ] = useState(true);
    return (
        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={(e, reason) => { reason !== "clickaway" && setOpen(false) }} {...props} />
    )
}
